import React, { useContext, useState } from "react";
import "./ProfileInfo.scss";
import DivInput from "../Inputs/DivInput";
import DefaultButton from "../Buttons/DefaultButton";
import { Context } from "../..";
import { Button, Typography } from "antd";
import { isMobile } from "react-device-detect";
const ProfileInfo = ({
  focus,
  setFocus,
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
}) => {
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const { userStore } = useContext(Context);
  const saveInfo = async () => {
    setError("");
    setResult("");
    if (password === "" || confirmPassword === "") {
      setError("Заполните все поля!");
      return;
    }
    if (password !== confirmPassword) {
      setError("Пароли не совпадают!");
      return;
    }
    if (password === confirmPassword && password !== "") {
      await userStore.updatePassword(password, confirmPassword).then(
        () => {
          setResult("Изменение пароля прошло успешно!");
        },
        () => {
          setError("Что-то пошло не так!");
        }
      );
    }
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <div className="info">
      <div className="profile__info__password">
        <Typography.Title
          level={3}
          style={{
            margin: 0,
            textAlign: "center",
          }}
        >
          Изменение пароля
        </Typography.Title>
        {result !== "" && <p className="password__result">{result}</p>}
        {error !== "" && <p className="password__error">{error}</p>}
        <div className="info__input" onClick={() => setFocus(1)}>
          <p className="input_title">Новый пароль</p>
          {isMobile ? (
            <DivInput
              placeholder="Пароль..."
              value={password}
              focus={focus === 1}
            />
          ) : (
            <input
              className="password_input"
              placeholder="Пароль..."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
        </div>
        <div className="info__input" onClick={() => setFocus(2)}>
          <p className="input_title">Повторите пароль</p>
          {isMobile ? (
            <DivInput
              placeholder="Повторите пароль..."
              value={confirmPassword}
              focus={focus === 2}
            />
          ) : (
            <input
              className="password_input"
              placeholder="Повторите пароль..."
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}
        </div>
      </div>
      <div className="info__save_button">
        <Button
          style={{ height: 40, width: 200, borderRadius: 4, fontSize: 18 }}
          type="primary"
          onClick={saveInfo}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};

export default ProfileInfo;
