import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Context } from "..";

import ProfileInfo from "../components/Profile/ProfileInfo";

import "../styles/ProfilePage.scss";
const ProfilePage = () => {
  const [focus, setFocus] = useState(0);
  const { userStore } = useContext(Context);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const updateCount = (num) => {
    let value;
    if (focus === 1) value = password;
    if (focus === 2) value = confirmPassword;
    if (num === "<") {
      if (value !== "0") {
        value = value.slice(0, -1);
      }
    } else if (value.length < 15) {
      if (value === "0") value = num.toString();
      else {
        value = value + num.toString();
      }
    }
    if (focus === 1) setPassword(value);
    if (focus === 2) setConfirmPassword(value);
  };

  return (
    <div className="profile">

      <div className="profile__info">
        <div className="profile__info_inputs">
          <ProfileInfo
            focus={focus}
            setFocus={setFocus}
            password={password}
            confirmPassword={confirmPassword}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
          />
        </div>
      </div>
      {/* <div className="profile__right_bar">
        <div className="right_bar__keyboard">
          <Keyboard handler={updateCount} />
        </div>
      </div> */}
    </div>
  );
};

export default observer(ProfilePage);
