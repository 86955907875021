export const LOGIN_ROUTE = "/login";
export const MAIN_ROUTE = "/factory";
export const USERS_ROUTE = "/f-users";
export const SETTINGS_ROUTE = "/f-settings";
export const WORK_ROUTE = "/operation_watches";
export const WORK_HISTORY_ROUTE = "/operation_watches_history";
export const EDITOR_PAGE = "/f-editor";

export const primaryColor = "#3f9bf3"; // 'rgb(0 156 164 / 88%)';
export const primaryHoverColor = "#023FF0";
export const primaryLoadingColor = "#0502E6";
export const defaultTextColor = "#c4c4c4";
export const primaryTextColor = "#ffffff";
export const labelTextColor = "#364651";
export const redColor = "#F13131";
export const greenColor = "#47bd2a";
export const greyColor = "#C4C6C0";
export const warningColor = "#FF9900";
export const blueColor = "#0c76da";
