import React, { useContext, useEffect, useState } from "react";
import "./Login.scss";
import { Context } from "../..";
import { isMobile } from "react-device-detect";
import DivInput from "../../components/Inputs/DivInput";
import Keyboard from "../../components/Keyboard/Keyboard";
import { observer } from "mobx-react-lite";
import { Form, Input, Button, Checkbox, Image, Typography, Select } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "./logo.svg";
import "./Login.scss"
import { useHistory } from "react-router";

const LoginPage = () => {
  const { userStore } = useContext(Context);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errStatus, setErrorStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersSelect, setUsersSelect] = useState([]);
  const formRef = React.createRef();
  const [error, setError] = useState(false);


  const history = useHistory()
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      //borderBottom: "1px solid #1890ff",

      padding: 10,
      width: "100%",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "aliceblue" : "white",
      height: 50,
      border: "1px solid #188fff9a  ",
      fontSize: 22,
      "&:hover": {
        border: "1px solid #1890ff",
      },
    }),
  };
  useEffect(() => {
    async function getUsers() {
      let response = await userStore.getUsers().then(() => {
        setUsers(userStore.users);
        setUsersSelect(
          userStore.users.map((user) => {
            return { value: user.email, label: user.name };
          })
        );
      });
    }
    getUsers();
  }, []);

  const login = (values) => {
    setError(false);
    const username = values.username;
    const password = values.password;
    userStore
      .login(username, password).then(() => {
        setError(false);
        userStore.getUser().then(() => {
          if (userStore.doneAuth) userStore.setAuth(true);
          history.push("/factory")
        }).catch((e) => {
          console.log(e);
          setError(true);
        }).finally(() => {
          setLoading(false);
        });

      })
      .catch((e) => {
        const status = e.status;
        if (status === 400) {
          setError(true);
        } else {
          setError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // UserService.login(username, password).then(response => {

    //     if (values.remember) {
    //         localStorage.setItem("username", username);
    //         localStorage.setItem("password", password);
    //     } else {
    //         localStorage.removeItem("username");
    //         localStorage.removeItem("password");
    //     }
    //     const access_token = response.data.access_token;
    //     const refresh_token = response.data.refresh_token;

    //     localStorage.setItem("access_token", access_token);
    //     localStorage.setItem("refresh_token", refresh_token);
    //     UserService.getUserInfo().then(response => {
    //         const newUser = response.data.user
    //         dispatch(setLogin())
    //         dispatch(setUserInfo({ ...newUser, access_token, refresh_token }))
    //         localStorage.setItem("user_project", JSON.stringify(newUser));
    //         history.push("/projects")
    //     }).catch(error => {
    //         setError(true);
    //     })
    // }).catch((error) => {
    //     setError(true);
    //     console.log("error", error);
    // });
    //UserService.login(...values);
  }
  return (
    <div className="signin">
      <Form
        name="normal_username"
        className="username-form signin__form"
        onFinish={login}
        initialValues={{ remember: false }}
        ref={formRef}
      >
        <div className="signin__image-container">
          <div className="signin__image-frame">
            <Image preview={false} src={logo} alt="logo" />
          </div>
          <div className="signin__image-description">Производство</div>
        </div>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Вход
        </Typography.Title>

        <Form.Item
          name="username"
          validateStatus={error ? "error" : undefined}
          rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}

        >
          <Select
            autoComplete="no-complete"
            showSearch
            //prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="E-mail"
            filterOption={(input, option) => {
              return (
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            options={usersSelect}
          // value={isLoginAvailable ? username : ""}
          />
        </Form.Item>

        <Form.Item
          name="password"

          validateStatus={error ? "error" : undefined}
          rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
          help={error ? "Проверьте правильность данных" : undefined}
        >
          <Input
            autoComplete="no-complete"
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Пароль"
          // value={isPassAvailable ? password : ""}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="username-form-button">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(LoginPage);
