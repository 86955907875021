import React, { createContext } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import OperationStore from "./store/OperationStore";
import UserStore from "./store/UserStore";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import moment from "moment";
import 'moment/locale/ru';
moment.locale("ru");
export const Context = createContext(null);
ReactDOM.render(
  <ConfigProvider locale={ruRU}>
    <Context.Provider
      value={{
        userStore: new UserStore(),
        operationStore: new OperationStore(),
      }}
    >
      <App />
    </Context.Provider>
  </ConfigProvider>,
  document.getElementById("root")
);
