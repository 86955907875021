import { makeAutoObservable } from "mobx";
import moment from "moment";
import OperationService from "../services/OperationService";
import "moment/locale/ru";

moment.locale("ru");

export default class OperationStore {
  operations = [];
  operationHistory = [];
  operationWork = [];
  usersAllow = [];
  sumAll = 0
  sumPage = 0
  currentPage = 1
  pageSize = 100
  operationsCount = 0;
  workCount = 0;
  filters = []
  filtersWork = []

  setFilters(filters) {
    this.filters = filters;
  }
  setFiltersWork(filtersWork) {
    this.filtersWork = filtersWork;
  }
  setOperationsCount(operationsCount) {
    this.operationsCount = operationsCount;
  }
  setWorkCount(workCount) {
    this.workCount = workCount;
  }
  setPageSize(pageSize) {
    this.pageSize = pageSize;
  }
  setSumAll(sumAll) {
    this.sumAll = sumAll;
  }
  setCurrentPage(currentPage) {
    this.currentPage = currentPage;
  }
  setSumPage(sumPage) {
    this.sumPage = sumPage;
  }
  constructor() {
    makeAutoObservable(this);
  }

  setUsersAllow(users) {
    this.usersAllow = users;
    console.log(this.usersAllow);
  }
  setOperations(operations) {
    this.operations = operations;
  }
  setOperationHistory(operationHistory) {
    this.operationHistory = operationHistory;
  }
  setWorkHistory(workHistory) {
    this.workHistory = workHistory;
  }

  deleteOperation_(id) {
    const index = this.operationHistory.findIndex(
      (operation) => operation.id === id
    );
    console.log("DELETE OPRATION INDEX ", index);
    this.operationHistory.splice(index, 1);
  }
  updateOperationQty(id, operation) {
    const index = this.operationHistory.findIndex(
      (operation) => operation.id === id
    );
    this.operationHistory[index].qty = operation.qty;
    this.operationHistory[index].sum = operation.price;
  }
  updateOperation_(id, operation) {
    const index = this.operationHistory.findIndex(
      (operation) => operation.id === id
    );
    console.log("UPDATE OPERATION", index, operation);
    this.operationHistory[index] = operation;
  }
  setOperationHistoryCode(operationHistory) {
    this.operationHistory = operationHistory;
  }

  async getOperations(earch, limit, page) {
    try {
      const response = await OperationService.getOperations(earch, limit, page);
      this.setOperations(response.data);
      return response.data
    } catch (e) {
      throw e.response;
    }
  }
  async getOperationsWork(earch, limit, page) {
    try {
      const response = await OperationService.getOperations(earch, limit, page);
      return response.data
    } catch (e) {
      throw e.response;
    }
  }
  async deleteOperation(operation_id) {
    try {
      const response = await OperationService.deleteOperation(operation_id);
      return response;
    } catch (e) {
      throw e.response;
    }
  }
  async updateOperation(operation) {
    try {
      const response = await OperationService.updateOperation(operation);
      return response;
    } catch (e) {
      throw e.response;
    }
  }
  async updateOperationArr(operations) {
    try {
      const response = await OperationService.updateOperationArr(operations);
      return response;
    } catch (e) {
      throw e.response;
    }
  }
  async createOperation(operation) {
    try {
      const response = await OperationService.createOperation(operation);
      return response;
    } catch (e) {
      throw e.response;
    }
  }
  async sendOperationHistory(operations) {
    try {
      const response = await OperationService.sendOperationHistory(operations);
      this.setOperations(response.data);
    } catch (e) {
      throw e.response;
    }
  }
  async getOperationHistory(filters, pageSize, currentPage, groupMode = false) {
    try {
      const response = await OperationService.getOperationHistory(filters, pageSize, currentPage, groupMode);
      this.setOperationHistory(response.data.operations);
      this.setOperationsCount(response.data.count);
      this.setSumPage(response.data.page_sum.toFixed(2));
      this.setSumAll(response.data.all_sum.toFixed(2))
      console.log(response.code);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async getWorkHistory(filters, pageSize, currentPage, with_trash = false) {
    try {
      const response = await OperationService.getWatchOperations(filters, pageSize, currentPage, with_trash);
      this.setWorkHistory(response.data.result);
      this.setWorkCount(response.data.count);

      console.log("getWorkHistory", response);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async getUsersAllow() {
    try {
      const filters = [
        {
          comparison: ">",
          field: "delete_mark",
          value: 0,
        },
      ];
      const response = await OperationService.getOperationHistoryAllow(filters);
      let users = [];

      response.data.operations.forEach((operation) => {
        if (!users.includes(operation.user_id)) {
          users.push(operation.user_id);
        }
      });
      this.setUsersAllow(users);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async deleteOperationHistory(id) {
    try {
      const response = await OperationService.deleteOperationHistory(id);
      console.log(response.data);
      this.deleteOperation_(id);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async createWatchOperation(operation) {
    try {
      const response = await OperationService.createWatchOperation(operation);
      console.log(response.data);
      return response;
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async updateWatchOperation(operation) {
    try {
      const response = await OperationService.updateWatchOperation(operation);
      console.log(response.data);
      return response;
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async getWatchOperations(filters, pageSize, currentPage, with_trash = false) {
    try {
      const response = await OperationService.getWatchOperations(filters, pageSize, currentPage, with_trash);
      console.log(response.data);
      return response;
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async deleteWatchOperation(id) {
    try {
      const response = await OperationService.deleteWatchOperation(id);
      console.log("deleteWatchOperation", response);
      return response;
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async deleteOperationHistoryUser(id) {
    try {
      await OperationService.deleteOperationHistoryUser(id);
      this.deleteOperation_(id);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async updateOperationHistory(id, updatedData) {
    try {
      const response = await OperationService.updateOperationHistory(
        id,
        updatedData
      );
      //this.updateOperationQty(response.data.id, response.data);
      this.updateOperation_(response.data.id, response.data);
      return response
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
}
