import { $api } from "../http";

export default class OperationService {
  static async getOperations(search, limit, page) {
    return $api.get("/api/dir_operations", {
      params: { limit, page, search },
    });
  }

  static async createOperation(operation) {
    return $api.post("/api/dir_operations", operation);
  }
  static async updateOperation(operation) {
    return $api.put("/api/dir_operations", { data: [operation] });
  }
  static async updateOperationArr(operations) {
    return $api.post("/api/dir_operations_history_mass_update", { data: operations });
  }
  static async createWatchOperation(operation) {
    return $api.post("/api/operation_watches", operation);
  }
  static async updateWatchOperation(operation) {
    return $api.put("/api/operation_watches/" + operation.id, operation);
  }
  static async deleteWatchOperation(id) {
    return $api.delete("/api/operation_watches/" + id);
  }
  static async getWatchOperations(filters, limit, page, with_trash) {
    return $api.post("/api/operation_watches/search?order_by=start&order_type=DESC&limit=" + limit + "&page=" + page + `${with_trash ? "&with_trashed" : ""}`, {

      filters: filters,
    });
  }
  static async deleteOperation(operation_id) {
    return $api.delete("/api/dir_operations/" + operation_id);
  }
  static async getOperationHistory(filters, limit, page, groupMode) {
    return $api.post("/api/dir_operations_history_search?order_by=created_at&order_type=DESC&limit=" + limit + "&page=" + page + `${groupMode ? "&groupByCode=true" : ""}`, {

      filters: filters,
    });
  }
  static async getOperationHistoryAllow(filters) {
    return $api.post("/api/dir_operations_history_search?order_by=created_at&order_type=DESC&limit=" + 500, {

      filters: filters,
    });
  }
  static async sendOperationHistory(operations) {
    return $api.post("/api/dir_operations_history", {
      data: operations,
    });
  }
  static async updateOperationHistory(id, updatedData) {
    return $api.put("/api/dir_operations_history/" + id,
      { ...updatedData }
    );
  }
  static async deleteOperationHistory(id) {
    return $api.delete("/api/dir_operations_history/" + id);
  }
  static async deleteOperationHistoryUser(id) {
    return $api.put("/api/dir_operations_history/" + id, { delete_mark: 1 });

  }
}
