import React, { useState, useEffect } from "react";
import { Card, Typography, Table, Modal, Button, Input, message, Form, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "../../styles/Card.scss";
import "./SettingsPage.scss"
import { useContext } from "react";
import { Context } from "../..";

const editor_search = [
    {
        field: "code",
        comparison: "like",
        value: "",
    },
    {
        field: "ref",
        comparison: "like",
        value: "",
    },
    {
        field: "title",
        comparison: "like",
        value: "",
    },

];

export default function SettingsPage() {
    const { operationStore } = useContext(Context);

    const [reloadPage, setReloadPage] = useState(true);
    const [editOperation, setEditOperation] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [addModalVis, setAddModalVis] = useState(false);
    const [createNew, setCreateNew] = useState(true);
    const [inputValue, updateInputValue] = useState("");
    const [tableOperations, setTableOperations] = useState([]);
    const [updatedIndex, setUpdatedIndex] = useState(0);
    const [search, setSearch] = useState(
        editor_search
        // userStore.user.capabilities !== "f_user" ? editor_search : user_search
    );
    const [form] = Form.useForm();

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoading(true);
            operationStore
                .getOperations(inputValue, 500, 1)
                .then((response) => {
                    setTableOperations(response)
                    setIsLoading(false);
                })
                .catch((e) => console.log(e))
                .finally(() => { });
        }, 700)
        return () => {
            clearTimeout(timeout);
        }
    }, [inputValue])

    useEffect(() => {
        // if (Object.keys(editOperation).length === 0) return;
        if (editOperation === undefined) return;
        if (editOperation.code === "") {
            form.setFieldsValue(editOperation);
            setCreateNew(true);
        } else {
            // if (editOperation_.capabilities != "")
            //   editOperation_.capabilities = editOperation_.capabilities.split(", ").map(cap => { return { value: unroles[cap], label: cap } })

            form.setFieldsValue(editOperation);
            setCreateNew(false);
        }
        setAddModalVis(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editOperation]);

    useEffect(() => {

        const getAllOperations = async () => {
            setIsLoading(true);
            operationStore.getOperations().then(() => {
                setIsLoading(false);
                setTableOperations(operationStore.operations)
            })

        }
        getAllOperations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPage]);


    function sendForm() {
        form
            .validateFields()
            .then((values) => {

                const copy = Object.assign({}, values);

                // copy.phone = "+" + copy.phone
                //   .replace(/[^a-zA-Z0-9 ]/g, "")
                //   .split(" ")
                //   .join("");
                // if (copy.phone === "+7") {
                //   setPhoneError(true);
                //   return;
                // }
                if (createNew) {

                    operationStore.createOperation({ ...copy }).then(response => {
                        console.log(response);
                        setUpdatedIndex(editOperation.id);
                        setAddModalVis(false);
                        form.resetFields();
                        setTableOperations([...tableOperations, response.data])
                        setTimeout(() => {
                            setUpdatedIndex(0)
                        }, 1000)
                    })
                    // OperationService.createOperation(copy).then((resposne) => {
                    //   if (resposne.status === 500) {
                    //     throw "error"
                    //   }
                    //   setAddModalVis(false);
                    //   form.resetFields();
                    //   message.success("Пользователь добавлен");
                    //   setReloadPage(!reloadPage);
                    //   setUpdatedIndex(editOperation.id)
                    //   setTimeout(() => {
                    //     setUpdatedIndex(0)
                    //   }, 1000)

                    // }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
                } else {
                    operationStore.updateOperation({ ...copy, id: editOperation.id }).then((resposne) => {
                        setAddModalVis(false);
                        form.resetFields();
                        message.success("Операция обновлена!");
                        setReloadPage(!reloadPage);
                        setUpdatedIndex(editOperation.id)
                        setTableOperations(tableOperations.map(operation_ => {
                            if (operation_.id === editOperation.id) {
                                return { ...operation_, ...copy }
                            }
                            return operation_
                        }))
                        setTimeout(() => {
                            setUpdatedIndex(0)
                        }, 1000)
                    }).catch(error => message.error("Не удалось обновить операцию!"))
                    // OperationService.updateOperation(editOperation.id, copy).then((resposne) => {
                    //   setAddModalVis(false);
                    //   form.resetFields();
                    //   message.success("Пользователь обновлен");
                    //   setReloadPage(!reloadPage);
                    //   setUpdatedIndex(editOperation.id)
                    //   setTimeout(() => {
                    //     setUpdatedIndex(0)
                    //   }, 1000)
                    // }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
                }


            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }

    const deleteOperation = (operation) => {
        operationStore.deleteOperation(operation.id).then(response => {
            setTableOperations(tableOperations.filter(operation_ => operation_.id !== operation.id))
            message.success("Операция " + operation.title + " успешно удалёна!")
        }).catch(error => message.error("Не удалось удалить операцию!"))
    }

    const updateValue = (newValue, key) => {
        const index = search.findIndex((element) => element.field === key);
        search[index].value = newValue;
        setSearch(() => [...search]);
        searchResult(search);
    };
    const searchResult = async (search) => {
        setIsLoading(true);

        const searchF = JSON.parse(JSON.stringify(search));

        let filters = searchF.filter((element) => {
            if (element.value !== "" && element.value !== null) return true;
            return false;
        });
        operationStore.setFilters(filters);
        await operationStore.getOperations(filters).then(() => {
            setIsLoading(false);

        });
    };
    return (
        <div>
            <Card
                title={<div className="filter-field__item" style={{ marginRight: 15 }}>
                    <Input

                        placeholder="Поиск по коду или наименованию"
                        size="large"
                        onChange={(e) => updateInputValue(e.target.value)}


                    />
                </div>}

                extra={
                    <Button
                        type="default"
                        // shape="circle"
                        icon={<PlusOutlined />}
                        style={{ marginLeft: 15 }}
                        onClick={() => {
                            setEditOperation({

                                price: "",
                                code: "",
                                title: "",
                                ref: null,
                            });
                        }}
                    >
                        {window.screen.width > 450 ? "Добавить" : ""}

                    </Button>
                }
                bordered={false}
                className="card"
            >
                <Table
                    pagination={{ position: ["topRight"] }}
                    // pagination={{
                    //   defaultCurrent: 1,
                    //   total: 500,
                    //   pageSize: 500,
                    // }}
                    rowClassName={(record, index) => record.id === updatedIndex ? 'table-row start-animate ' : 'table-row'}
                    bordered={true}
                    loading={isLoading}
                    columns={[
                        {
                            title: "Код",
                            dataIndex: "code",
                            key: "code",
                        },
                        {
                            title: "Наименование",
                            dataIndex: "title",
                            key: "title",

                        },
                        {
                            title: "Стоимость",
                            dataIndex: "price",
                            key: "price",
                            render: (text) => (<div>{text}</div>)
                        },


                        {
                            title: "",
                            dataIndex: "action",
                            key: "action",
                            render: (_, full) => (
                                <Typography.Link
                                    href="#"
                                    onClick={(e) => {
                                        setAddModalVis(true)
                                        e.preventDefault();
                                        setEditOperation(full);
                                    }}
                                >
                                    <EditOutlined />
                                </Typography.Link>
                            ),
                        },
                        {
                            title: "",
                            dataIndex: "delete",
                            key: "delete",
                            render: (_, full) => (
                                <Popconfirm title="Удалить операцию?" placement="topLeft" onConfirm={() => deleteOperation(full)} okText="Да" cancelText="Нет">

                                    <Typography.Link

                                        onClick={(e) => {

                                            e.preventDefault();
                                            //deleteOperation(full)
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Typography.Link>
                                </Popconfirm>
                            ),
                        },
                    ]}
                    dataSource={tableOperations ? tableOperations : []}
                    scroll={{ x: 300 }}
                    rowKey="id"
                />
            </Card>


            <Modal
                title={
                    <Typography.Title level={4}>{createNew ? "Добавить" : "Обновить"} пользователя</Typography.Title>
                }
                centered
                visible={addModalVis}
                onOk={sendForm}
                okText={createNew ? "Добавить" : "Обновить"}
                cancelText={"Отмена"}
                onCancel={() => setAddModalVis(false)}
                closable={false}
                destroyOnClose
                forceRender
                width={window.screen.width < 450 ? "100%" : 450}
                // zIndex={10000}
                bodyStyle={{ paddingBottom: 16 }}
            >
                <Form
                    name="wrap"
                    // labelCol={{ flex: "110px" }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                    form={form}
                    initialValues={editOperation}
                    layout="vertical"
                >


                    <Form.Item
                        label="Код"
                        required
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder="Введите код" className="settings__input" />
                    </Form.Item>
                    <Form.Item
                        label="Наименование"
                        required
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder="Введите наименование" className="settings__input" />
                    </Form.Item>
                    <Form.Item
                        label="Стоимость"
                        required
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: "Поле обязательно к заполнению",
                            },
                        ]}
                    >
                        <Input placeholder="Введите стоимость" className="settings__input" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
