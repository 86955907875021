import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import "../styles/Card.scss";
import "../styles/EditorPage.scss";
import { Context } from "..";
import NewSearch from "../components/EditorPage/NewSearch"
import { Card, Modal, Input, Table, Typography, message, Pagination, Form, Button, Select, Switch, Divider, DatePicker } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, LikeOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/ru";
import styled from "styled-components";

moment.locale("ru");
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const { confirm } = Modal;

const StyledTable = styled(Table)`

`
const EditorPage = () => {
  const [error, setError] = useState("");
  const { operationStore } = useContext(Context);
  const { userStore } = useContext(Context);
  const [editorMode, setEditorMode] = useState(0);
  const [groupMode, setGroupMode] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm();
  const [updateModalShow, setUpdateModalShow] = useState(false);
  const [targetOperation, setTargetOperation] = useState({});
  const [checkedRow, setCheckedRow] = useState([]);
  const [isMobile, setIsMobile] = useState((window.screen.width) < 450)


  const getPageOperations = (page) => {
    setIsLoading(true)
    operationStore.getOperationHistory(operationStore.filters, operationStore.pageSize, page).then(() => {

      operationStore.setCurrentPage(page);
    }, () => {

    }).finally(() => {
      setIsLoading(false);
    });
  }

  const onChange = (page) => {
    console.log(page);
    getPageOperations(page);

  };
  const onShowSizeChange = (current, pageSize) => {
    operationStore.setPageSize(pageSize);
    operationStore.setCurrentPage(operationStore.currentPage);

  };
  const handleOk = async () => {
    setError(false);
    form
      .validateFields()
      .then((values) => {
        console.log({ ...values, created_at: targetOperation.created_at });
        operationStore.updateOperationHistory(targetOperation.id, { ...values, created_at: targetOperation.created_at }).then((result) => {
          console.log(result);
          message.success("Операция успешно обновлена!")
          setUpdateModalShow(false);
        }).catch(error => message.error("Не удалось обновить операцию!"))
      }).catch(error => console.log("Error form", error));

  };

  const handleCancel = () => {
    setUpdateModalShow(false);
  };

  useEffect(() => {
    if (updateModalShow) {
      form.setFieldsValue(targetOperation)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [updateModalShow])

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: 'Удалить опперацию?',
      icon: <ExclamationCircleOutlined />,
      content: title,
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отмена',
      onOk() {
        operationStore
          .deleteOperationHistory(id)
          .then(() => {
            getPageOperations(operationStore.currentPage);
            message.success("Операция удалена!")
          })
          .catch((error) => { });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const rowSelection = {
    selectedRowKeys: [...checkedRow],
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setCheckedRow(selectedRowKeys)
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  const allowCheckedItems = async () => {
    const operationArr = checkedRow.map(id => {
      return { id: id, delete_mark: 0 };
    })
    console.log("allow", operationArr);


    operationStore
      .updateOperationArr(operationArr)
      .then(() => {
        message.success("Операции одобрены!");
        setCheckedRow([]);
        getPageOperations(operationStore.currentPage);
      })
      .catch((error) => {
        message.error("Не удалось одобрить операции!");

      });
  };
  const deleteCheckedItems = async () => {


    await checkedRow.map(async (id) => {
      await operationStore
        .deleteOperationHistory(id)
        .then(() => {
          const index = checkedRow.findIndex((row_id) => row_id === id);
          checkedRow.splice(index, 1);
          setCheckedRow(checkedRow);
          getPageOperations(operationStore.currentPage);
        })
        .catch((error) => { });
    });

  };
  return (
    <div className="editor">
      <NewSearch viewMode={editorMode} groupMode={groupMode} />
      <Card
        style={{ marginBottom: 20 }}

        bordered={false}
        className="card"
      >
        <div style={{ marginBottom: 15, display: "flex", justifyContent: "flex-end", alignItems: "center", }}>

          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>

            <div className="info__text" style={{ display: "flex", flexDirection: "wrap", marginRight: 15 }}>
              <Typography.Text type="primary" className="bar_info__param" style={{ paddingRight: 10, borderRight: "1px solid #cccccc", marginRight: 15 }}>
                Сумма страницы  <Typography.Text strong>{operationStore.sumPage} ₽ </Typography.Text>
              </Typography.Text>
              <Typography.Text
                type="primary"
                className="bar_info__param"

              >
                Сумма общее <Typography.Text strong>{operationStore.sumAll} ₽ </Typography.Text>
              </Typography.Text>
            </div>
            <div style={{ display: "flex", width: isMobile ? "100%" : undefined, justifyContent: isMobile ? "flex-end" : undefined, marginTop: isMobile ? 15 : 0 }}>

              <Pagination
                current={operationStore.currentPage}
                total={operationStore.operationsCount}
                onChange={onChange}
                defaulPageSize={100}
                pageSize={operationStore.pageSize}
                onShowSizeChange={onShowSizeChange}
                showSizeChanger
              />
            </div>
          </div>
        </div>
        <StyledTable
          title={() => <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div style={{ marginRight: 15, display: "flex", alignItems: "center" }}>
              {userStore.user.capabilities.includes("f_editor") && <>  <Switch
                style={{ transform: [{ scaleX: .8 }, { scaleY: .8 }], marginRight: 20 }}

                checkedChildren={<LikeOutlined />}
                unCheckedChildren={<EyeOutlined />}
                onChange={(checked, event) => {
                  setCheckedRow([])
                  setEditorMode(checked)
                  operationStore.setCurrentPage(1);
                }}
              />
                <Typography style={{ fontSize: 15, borderRight: "1px solid #cccccc", paddingRight: 10, marginRight: 10 }}>{editorMode ? "Редактирование" : "Просмотр"}</Typography></>}
              {userStore.user.capabilities.includes("f_editor") && <>

                <Switch
                  style={{ transform: [{ scaleX: .8 }, { scaleY: .8 }], marginRight: 20 }}
                  onChange={(checked, event) => {
                    setCheckedRow([])
                    setGroupMode(checked)
                    operationStore.setCurrentPage(1);
                  }}
                  checked={groupMode}
                />
                <Typography style={{ fontSize: 15 }}>{"Группировать"}</Typography></>}
            </div>
            {!groupMode &&
              <div style={{ display: "flex", width: isMobile ? "100%" : undefined, justifyContent: isMobile ? "flex-end" : undefined, marginTop: isMobile ? 15 : 0 }}>
                {userStore.user.capabilities.includes("f_editor") && < Button type="danger" onClick={deleteCheckedItems} style={{ marginRight: editorMode === true ? 15 : 0 }}>
                  Удалить
                </Button>}
                {(editorMode === true || userStore.user.capabilities === 'f_user') && <Button type="primary" onClick={allowCheckedItems}>
                  {userStore.user.capabilities === 'f_user' ? "Отметить" : "Одобрить"}
                </Button>}
              </div>}
          </div>}
          pagination={false}
          rowSelection={{ ...rowSelection }}
          // pagination={{
          //   defaultCurrent: 1,
          //   total: 500,
          //   pageSize: 500,
          // }}
          rowClassName={(record, index) => record.delete_mark === 1 ? 'marked' : ''}
          bordered={true}
          dataSource={
            userStore.user.capabilities.includes("f_editor") ? [...operationStore.operationHistory] : [...operationStore.operationHistory.filter(
              (operation) => operation.delete_mark === 0
            )]
          }

          loading={isLoading}
          columns={
            [
              {
                title: "Дата",
                dataIndex: "created_at",
                key: "created_at",
                width: 150,
                render: (text) => (<div>{capitalizeFirstLetter(
                  moment(text).add("hours", 7).format("D  MMM	 YYYY, H:mm")
                )}</div>),
              },
              {
                title: "Имя",
                dataIndex: "user_id",
                key: "user_id",
                render: (text) => (<div>{userStore.users.find(
                  (el) => el.id === Number(text)
                )?.name}</div>),
                width: 180,
              },
              {
                title: "Код",
                dataIndex: "code",
                key: "code",
                width: 120,
                render: (text, full) => (
                  <div style={{ display: "flex", alignItems: "center" }}>

                    <div style={{ marginRight: 10 }}>{text}</div>
                    {full.feature !== "" && full.feature !== null &&
                      <div style={{ width: 25, height: 25, borderRadius: 25, background: full.feature, boxShadow: "0px 1px 4px 2px #00000032" }}>

                      </div>
                    }
                  </div>
                ),
              },
              {
                title: "Операция",
                dataIndex: "title",
                key: "title",
                render: (text, full) => (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>

                    <div style={{ marginRight: 10 }}>{text}</div>
                    {full.comment !== "" && full.comment != null && <div style={{ color: "#1faa1f" }}> {full.comment}</div>}
                  </div>
                ),
              },
              {
                title: "Количество",
                dataIndex: "qty",
                key: "qty",
                width: 100,
                render: (text) => (<div>{text}</div>)
              },
              {
                title: "Цена",
                dataIndex: "price",
                key: "price",
                width: 100,
                render: (text) => (<div>{text}</div>)
              },
              {
                title: "Сумма",
                dataIndex: "sum",
                key: "sum",
                width: 100,
                render: (text) => (<div>{text}</div>)
              },
              {
                title: "",
                dataIndex: "action",
                key: "action",
                width: 50,
                render: (_, full) => (
                  <Typography.Link
                    disabled={groupMode}
                    onClick={(e) => {
                      //setAddModalVis(true)
                      //userStore.users.find(user => user.id === Number(targetOperation.user_id))?.name;
                      //console.log("FIND", userStore.users.find(user => user.id === Number(targetOperation.user_id)).name);
                      console.log(full);
                      setTargetOperation(full)
                      setUpdateModalShow(true);
                      e.preventDefault();
                      //setEditUser(full);
                    }}
                  >
                    <EditOutlined />
                  </Typography.Link>
                ),
              },
              {
                title: "",
                dataIndex: "delete",
                key: "delete",
                width: 50,

                render: (_, full) => (
                  <Typography.Link
                    disabled={groupMode}
                    onClick={(e) => {
                      showDeleteConfirm(full.id, full.title);

                      //deleteUser(full.id)
                    }}
                  >
                    <DeleteOutlined />
                  </Typography.Link>
                ),
              }
            ].filter(row => {
              if (userStore.user.capabilities === "f_user") {
                if (row.key !== "delete" && row.key !== "action") {
                  return true
                } else {
                  return false;
                }
              } else {
                return true
              }
            })
          }
          scroll={{ x: 300 }}
          rowKey="id"

        />
      </Card >
      <Modal
        visible={updateModalShow}
        forceRender
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 20 }}
        okText={"Сохранить"}

        cancelText={"Отмена"}
        footer={[

          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Сохранить
          </Button>,

        ]}
      >
        <Form layout="vertical"
          labelWrap
          wrapperCol={{ flex: 1 }}

          form={form}
        //initialValues={task}
        >
          <Form.Item
            label="Имя"
            name="user_id"
            validateStatus={error ? "error" : undefined}
            rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
            help={error ? "Проверьте правильность данных" : undefined}
          >

            <Select
              showSearch
              filterOption={(input, option) => {
                return (
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              options={userStore.users.map((user) => { return { value: user.id.toString(), label: user.name } })}
              placeholder="Имя"
            //value={}
            />
          </Form.Item >
          <Form.Item
            label="Код"
            name="code"
            validateStatus={error ? "error" : undefined}
            rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
            help={error ? "Проверьте правильность данных" : undefined}
          >
            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px" }}>{targetOperation.code}</Typography>
          </Form.Item >

          <Form.Item
            label="Операция"
            name="title"
            validateStatus={error ? "error" : undefined}
            rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
            help={error ? "Проверьте правильность данных" : undefined}
          >
            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px" }}>{targetOperation.title}</Typography>
          </Form.Item >

          <Form.Item
            label="Количество"
            name="qty"
            validateStatus={error ? "error" : undefined}
            rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
            help={error ? "Проверьте правильность данных" : undefined}
          >
            <Input placeholder="Количество" />
          </Form.Item >
          <Form.Item
            label="Цена"
            name="price"
            validateStatus={error ? "error" : undefined}
            rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
            help={error ? "Проверьте правильность данных" : undefined}
          >
            <Input placeholder="Цена" />
          </Form.Item >

          <Form.Item
            label="Сумма"
            name="sum"
            validateStatus={error ? "error" : undefined}
            rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
            help={error ? "Проверьте правильность данных" : undefined}
          >
            <Typography style={{ fontSize: 15, border: "1px solid #d9d9d9", fontVariant: "tabular-nums", padding: "4px 11px" }}>{targetOperation.sum}</Typography>
          </Form.Item >
          <Form.Item
            label="Дата"

            validateStatus={error ? "error" : undefined}
            rules={[{ required: true, message: "Пожалуйста, заполните поле!" }]}
            help={error ? "Проверьте правильность данных" : undefined}
          >
            <DatePicker allowClear={false} style={{ width: "100%" }} value={targetOperation !== null ? moment(targetOperation?.created_at) : undefined} format="DD.MM.YYYY"
              onChange={(date) => {
                if (date !== null) {
                  setTargetOperation({ ...targetOperation, created_at: date.format('YYYY-MM-DDTHH:mm:ss[Z]') })
                }
                console.log(date);
              }}
            />
          </Form.Item >
        </Form>
      </Modal>

    </div >
  );
};

export default observer(EditorPage);
