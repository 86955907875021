import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Pagination, Popconfirm, Switch, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../..';
import NewSearchWork from '../../components/EditorPage/NewSearchWork';
import "./WorkHistory.scss"
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const WorkHistoryPage = () => {
    const { operationStore } = useContext(Context);
    const { userStore } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false)
    const [pageSize, setPageSize] = useState(100);
    const [page, setPage] = useState(1);
    const [isMobile, setIsMobile] = useState((window.screen.width) < 450)
    const [withTrash, setWithTrash] = useState(false)



    useEffect(() => {
        setIsLoading(true);
        operationStore.getWorkHistory(operationStore.filtersWork, pageSize, page, withTrash).then(response => {
            console.log("Get operatons user", response);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [page, withTrash])

    const getTimeDiff = (startTime, endTime) => {
        const hours = endTime.diff(startTime, 'hours');
        const minutes = endTime.diff(startTime, 'minutes');
        return `${hours} часов ${minutes % 60} ${minutes % 60 == 1 ? "минуту" : minutes % 60 > 1 && minutes % 60 < 5 ? "минуты" : "минут"}`
    }

    const onShowSizeChange = (current, pageSize) => {
        setPage(1);
        setPageSize(pageSize);
    }

    const onChangePage = (page) => {
        setPage(page);
    }

    return (
        <div className='work_history'>
            <div>
                <NewSearchWork setLoading={setIsLoading} setPage={setPage} withTrash={withTrash} />
            </div>
            <div className='work_history__table'>
                <div style={{ display: "flex", width: isMobile ? "100%" : undefined, justifyContent: "space-between", marginTop: isMobile ? 15 : 0, marginBottom: 15 }}>
                    {userStore.user.capabilities.includes("f_editor") &&
                        <div style={{ display: "flex" }}>

                            <Switch
                                style={{ transform: [{ scaleX: .8 }, { scaleY: .8 }], marginRight: 20 }}
                                onChange={(checked, event) => {
                                    setWithTrash(checked)
                                    operationStore.setCurrentPage(1);
                                }}
                                checked={withTrash}
                            />
                            <Typography style={{ fontSize: 15 }}>{"С удалёнными"}</Typography>
                        </div>}
                    <Pagination
                        current={page}
                        total={operationStore.workCount}
                        onChange={onChangePage}
                        defaulPageSize={100}
                        pageSize={pageSize}
                        onShowSizeChange={onShowSizeChange}
                        showSizeChanger
                    />
                </div>
                <Table
                    pagination={false}
                    bordered={true}
                    loading={isLoading}
                    rowKey="id"
                    rowClassName={(row) => {
                        return row.deleted_at == null ? "" : "deleted"
                    }}
                    columns={[
                        {
                            title: "Код",
                            dataIndex: "code",
                            key: "code",
                        },
                        {
                            title: "Наименование",
                            dataIndex: "title",
                            key: "title",

                        },
                        {
                            title: "Серийный номер",
                            dataIndex: "qrcode",
                            key: "qrcode",
                        },
                        {
                            title: "Время работы",
                            dataIndex: "start",
                            key: "start",
                            render: (_, record) => (<div>{getTimeDiff(moment(record.start), moment(record.end))}</div>),
                        },
                        {
                            title: "Время начала",
                            dataIndex: "start",
                            key: "start",
                            render: (text) => (<div>{capitalizeFirstLetter(
                                moment(text).format("D  MMM	 YYYY, H:mm")
                            )}</div>),
                        },
                        {
                            title: "Время завершения",
                            dataIndex: "end",
                            key: "end",
                            render: (text) => (<div>{capitalizeFirstLetter(
                                moment(text).format("D  MMM	 YYYY, H:mm")
                            )}</div>),
                        },


                    ]}
                    dataSource={operationStore.workHistory ? operationStore.workHistory : []}

                />
            </div>
        </div>
    );
};

export default WorkHistoryPage;