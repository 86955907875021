import React, { useContext, useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { LOGIN_ROUTE, MAIN_ROUTE } from "../utils/consts.js";
import { observer } from "mobx-react-lite";
import { authRoutes, publicRoutes } from "../routes";
import { Context } from "../index.js";
import { Layout } from 'antd';

import "normalize.css";
import Sidebar from "./Sidebar/Sidebar.js";
import Header from "./Header/Header.js";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const AppRouter = observer(() => {
  const { userStore } = useContext(Context);
  const [collapsed, setCollapsed] = useState(window.screen.width > 450 ? false : true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const access_token = cookies.get("accessToken");
    const refresh_token = cookies.get("refreshToken");
    const user_ = cookies.get("user");


    if (access_token && user_) {
      if (user_.capabilities?.includes("f_editor") || user_.capabilities?.includes("super_admin")) {
        userStore.loginCookie(user_, access_token, refresh_token)
      }
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [])

  return (
    <Switch>
      {!loading &&
        <>
          {userStore.isAuth ?

            <Layout style={{ height: "100vh" }}>
              <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
              <Layout className="site-layout">
                <Header state={collapsed} setState={setCollapsed} />
                <Layout.Content
                  style={{
                    padding: "16px",
                    paddingLeft: window.screen.width > 450 ? "16px" : 0,
                    paddingRight: window.screen.width > 450 ? "16px" : 0,
                    overflowY: "auto",
                  }}
                >
                  {authRoutes.map(({ path, Component }) => (
                    <Route key={path} path={path} component={Component} exact />
                  ))}
                </Layout.Content>
              </Layout>
            </Layout>
            :
            publicRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} component={Component} exact />
            ))
          }

          {userStore.isAuth ? (
            <Redirect to={MAIN_ROUTE} />
          ) : (
            <Redirect to={LOGIN_ROUTE} />
          )}
        </>}
    </Switch>
  );
});

export default AppRouter;
