import { Button, Modal } from 'antd';
import React from 'react';
import QrReader from 'react-web-qr-reader';

const QrCodeReader = ({ handleError, handleScan, showViewFinder, setShowViewFinder }) => {
    return (
        <Modal
            title="Отсканируйте код"
            centered
            bodyStyle={{
                display: "flex",
                justifyContent: "center",
                width: "100% !important"
            }}
            style={{
                width: "100%"
            }}
            visible={showViewFinder}
            onCancel={() => setShowViewFinder(false)}

            footer={[
                <Button type="primary" onClick={() => setShowViewFinder(false)}>
                    Отмена
                </Button>,
            ]}

        >
            <QrReader
                delay={400}
                style={{
                    height: "90%",
                    width: "90%",
                }}
                legacyMode={true}
                constraints={{
                    facingMode: 'environment'
                }}
                showViewFinder={showViewFinder}
                onError={handleError}
                onScan={handleScan}
            />
        </Modal>

    );
};

export default QrCodeReader;