import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import "./OperationsList.scss";
import { Button, Divider, Empty, Input, Progress, Result, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const OperationList = ({
  operationArr,
  deleteOperationFromArr,
  focus,
  setFocus,
  updateComment,

  updateFeature,
  result,
  error,
  handler,
  noLogout,
  progress,
  setProgress,
  errorOperation,
  errorOperationFeature
}) => {
  const itemsRef = useRef([]);
  const [isMobile, setIsMobile] = useState((window.screen.width) < 450)

  // const addToRefs = (el) => {
  //   if (el && !itemsRef.current.includes(el)) {
  //     itemsRef.current.push(el);
  //   }
  // };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(intervalId);
          return 100;
        } else {
          return prev + 2;
        }
      });
    }, 100);
    return () => clearInterval(intervalId);
  }, [result]);

  useEffect(() => {

    itemsRef.current = itemsRef.current.slice(0, operationArr.length);
  }, [operationArr]);
  useEffect(() => {
    if (focus > 0 && focus !== 100) {
      itemsRef?.current[focus]?.focus();
    }
  }, [focus]);

  return (
    <>
      <div className="operations">
        {result !== "" && <div className="operations__result"><Result
          style={{ marginTop: 60 }}
          status="success"
          title="Успешно!"
          subTitle={result}
          extra={[
            <Button onClick={noLogout} style={{ width: 150, height: 40 }} type="primary" key="console">
              Остаться
            </Button>,


          ]}

        />
          <Progress style={{}} percent={progress} showInfo={false} />
        </div>}
        {operationArr?.length <= 0 && result === "" && <Empty imageStyle={{ height: 400 }} description={false} />}
        {operationArr &&
          operationArr.map((operation, index) => (
            <div key={operation.key} style={{ width: "100%" }}>
              <div

                className="operation"
                onClick={(e) => {
                  e.stopPropagation();
                  setFocus(operation.id + 1);
                }}
              >
                <div className="operation__info" >
                  <div className="operation__info__code">
                    {operation.code}
                  </div >

                  <div className="operation__info__title" >
                    {operation.title}
                  </div >
                  {isMobile && <div className="operation__info__delete">
                    <Button type="primary" icon={<DeleteOutlined />} size={"large"} onClick={() => deleteOperationFromArr(operation.id)} />
                  </div >}
                </div>
                <div className="operation__input" style={{}}>
                  {(operation.code === "01.11" || operation.code === "04.11") &&
                    <div style={{ width: "50%", marginRight: 5, display: "flex", alignItems: "center" }}>

                      <div style={{ width: 25, height: 25, borderRadius: 25, background: operation.feature, boxShadow: "0px 1px 4px 2px #00000022" }}>

                      </div>
                      <div >

                        <Select
                          bordered={false}
                          size="middle"
                          placeholder="Цвет"
                          style={{ border: "none", marginLeft: 10, minWidth: 100 }}
                          status={errorOperationFeature.findIndex(error => {
                            return error === operation.key
                          }) !== -1 ? "error" : undefined}
                          value={operation.feature}
                          onChange={(value) => {
                            updateFeature(index, value)
                          }}
                          options={[
                            { label: "Белый", value: "white" },
                            { label: "Серый", value: "grey" },
                            { label: "Бежевый", value: "beige" },
                            { label: "Коричневый", value: "brown" },
                            { label: "Синий", value: "blue" },
                            { label: "Черный", value: "black" },
                            { label: "Красный", value: "red" },
                          ]} />
                        <div style={{ position: "absolute", fontSize: 10, color: "red" }}>
                          {errorOperationFeature.findIndex(error => {
                            return error === operation.key
                          }) !== -1 ? "Заполните поле" : <></>}
                        </div>
                      </div>
                    </div>}
                  <div className="operation__input__comment" style={{ width: (operation.code === "01.11" || operation.code === "04.11") ? "50%" : undefined }}>
                    <Input placeholder="Комментарий"
                      style={{ height: 50 }}
                      value={operation.comment}
                      bordered={false}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => updateComment(index, e.target.value)} />
                  </div>
                  <div className="operation__input__d" style={{ width: (operation.code === "01.11" || operation.code === "04.11") ? "20%" : undefined }}>
                    <div className={errorOperation.findIndex(error => {
                      return error === operation.key
                    }) !== -1 ? "operation__input__d__qty error" : "operation__input__d__qty"}>

                      <Input placeholder="Количество"
                        value={operation.qty}

                        onChange={(e) => handler(operation.key, e.target.value)}
                        style={{ height: 50 }} bordered={false} />

                    </div>
                    <div style={{ position: "absolute", fontSize: 10, color: "red" }}>
                      {errorOperation.findIndex(error => {
                        return error === operation.key
                      }) !== -1 ? "Заполните поле" : <></>}
                    </div>
                  </div>



                  {!isMobile && <div className="operation__input__delete">
                    <Button type="primary" onClick={() => deleteOperationFromArr(operation.key)} icon={<DeleteOutlined />} size={"large"} />
                  </div >}
                </div>
              </div>
              <Divider style={{ margin: "14px 0" }} />
            </div>
          ))}
      </div>
    </>
  );
};

export default observer(OperationList);
