import 'antd/dist/antd.css';
import "./App.scss";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { observer } from "mobx-react-lite";
import { Spin } from 'antd';
import 'antd/dist/antd.min.css'

console.log = () => { };
function App() {
  //const { userStore } = useContext(Context);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(false);
  //   // if (!userStore.isAuth)
  //   //   userStore
  //   //     .checkAuth()
  //   //     .then(() => {})
  //   //     .finally(() => {
  //   //       userStore.setLoading(false);
  //   //       setLoading(false);
  //   //     });
  // }, [userStore]);
  if (loading) return <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><Spin size="large" /></div>;
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default observer(App);
