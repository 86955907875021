import { observer } from "mobx-react-lite";
import React, { useContext, useRef, useState } from "react";
import "../styles/MainPage.scss";
import OperationList from "../components/MainPage/OperationList";
import { Context } from "..";
import Search from "../components/MainPage/Search";

import { Button, DatePicker, message } from "antd";
import Cookies from 'universal-cookie';
import moment from "moment";

const cookies = new Cookies();



const MainPage = () => {
  const [operationArr, setOperationArr] = useState([]);
  const [targetOperation, setTargetOperation] = useState({});
  const [errorOperation, setErrorOperation] = useState([]);
  const [errorOperationFeature, setErrorOperationFeature] = useState([]);
  const [focus, setFocus1] = useState(0);
  const { userStore } = useContext(Context);
  const { operationStore } = useContext(Context);
  const [value, setValue] = useState({ value: null, label: "" });
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [logoutFlag, setLogoutFlagState] = useState(false)
  const [progress, setProgress] = useState(0);
  const [date, setDate] = useState(moment())
  const logoutRef = useRef(logoutFlag);
  const setLogoutFlag = (flag) => {
    setLogoutFlagState(flag);
    logoutRef.current = flag;
  }
  const setFocus = (val) => {

    setFocus1(val);
    setError("");
  };
  const updateOperationQtyInArr = (key, newQty) => {

    const newQtyRaw = newQty.replace(",", ".");
    const pointCount = newQtyRaw.split('.').length - 1

    setOperationArr(
      operationArr.map((obj) => {
        if (obj.key === key) {
          return { ...obj, qty: pointCount > 1 ? newQtyRaw.replace(".", "") : newQtyRaw };
        } else {
          return obj;
        }
      })
    );
  };
  const updateOperationCommentInArr = (key, newComment) => {
    setOperationArr(
      operationArr.map((obj) => {
        if (obj.key === key) {
          return { ...obj, comment: newComment };
        } else {
          return obj;
        }
      })
    );
  };
  const updateOperationFeatureInArr = (key, newFeature) => {
    setOperationArr(
      operationArr.map((obj) => {
        if (obj.key === key) {
          return { ...obj, feature: newFeature };
        } else {
          return obj;
        }
      })
    );
  };

  const setFocusSelect = (ref) => {
    setResult("");
    setError("");
    ref.focus();
  };
  const updateQtyKeyboard = (key, num) => {
    setError("");
    const re = /^[0-9]/;

    if (num === '' || num.match(/^([0-9]{1,})?(\,)?(\.)?([0-9]{1,})?$/)) {
      const index = operationArr.findIndex((e) => e.key === key);
      if (index < 0) return;
      setErrorOperation([...errorOperation.filter(error => error !== key)])
      if (num.length < 7) {
        operationArr[index].qty = num;
        updateOperationQtyInArr(operationArr[index].key, operationArr[index].qty);

      }
    }

  };
  const updateComment = (index, comment) => {
    operationArr[index].comment = comment;

    updateOperationCommentInArr(
      operationArr[index].key,
      operationArr[index].comment
    );
  };
  const updateFeature = (index, feature) => {
    console.log("Update errorOperation", errorOperation);
    operationArr[index].feature = feature;
    setErrorOperationFeature([...errorOperationFeature.filter(error => error !== operationArr[index].key)])
    updateOperationFeatureInArr(
      operationArr[index].key,
      operationArr[index].feature
    );
  };

  const deleteOperationFromArr = (key) => {
    setError("");
    const delOperation = operationArr.findIndex((e) => e.key === key);
    operationArr.splice(delOperation, 1);
    console.log(delOperation);
    setOperationArr([...operationArr]);
  };

  const allowItems = async () => {
    setResult("");
    setError("");
    if (operationArr.length > 0) {
      // "ref": "123123",
      // "code": "123",
      // "title": "Сборка ШГ СМАРТ",
      // "price": 100,
      // "qty": "11"
      let errorOperation_ = [];
      let errorOperationF_ = [];
      operationArr.forEach(
        (obj) => {
          if (obj.qty === "0" || obj.qty === "") {
            errorOperation_.push(obj.key)
          }
        }
      );
      operationArr.forEach(
        (obj) => {
          if ((obj.code === "01.11" || obj.code === "04.11")) {
            if ((obj?.feature === "" || obj?.feature === null)) {
              errorOperationF_.push(obj.key)
            } else {
              let colorRepeat = false;
              let colorRepeatWindow = false;
              if (obj.code === "04.11") {
                colorRepeat = operationArr.find(item => (item.code === "04.11") && item.feature === obj.feature && item.key !== obj.key)
              }
              if (obj.code === "01.11") {
                colorRepeatWindow = operationArr.find(item => (item.code === "01.11") && item.feature === obj.feature && item.key !== obj.key)
              }
              console.log("colorRepeat", colorRepeat);
              if (colorRepeat || colorRepeatWindow) {
                errorOperationF_.push(obj.key)
              }
            }
          }
        }
      );
      console.log(errorOperation_);
      setErrorOperation([...errorOperation_])
      setErrorOperationFeature([...errorOperationF_])
      if (errorOperation_.length !== 0) {
        setError("Заполните количество во всех операциях!");
        return;
      }
      if (errorOperationF_.length !== 0) {
        return;
      }
      let check = true;
      operationArr.forEach(obj => {
        if (obj.code === "999" && obj.comment.length === 0) {
          message.error({
            content: 'Укажите комментарий для временной операции!',
            duration: 2,
            style: {
              marginTop: '23vh',
            },
          });
          check = false;
        }
        if (obj.code === "01.11" || obj.code === "04.11") {
          if (obj?.feature === "" || obj?.feature === null) {
            check = false;
            message.error({
              content: 'Укажите цвет для операций 01.11|04.11!',
              duration: 2,
              style: {
                marginTop: '23vh',
              },
            });

          }
        }
      })
      if (!check) return
      await operationStore
        .sendOperationHistory(
          operationArr.map((obj) => {
            return {
              ref: obj.ref,
              code: obj.code,
              title: obj.title,
              price: obj.price,
              feature: obj?.feature,
              comment: obj.comment,
              delete_mark: obj.delete_mark,
              qty: obj.qty,
              created_at: date.toISOString()
            };
          })
        )
        .then(
          () => {
            setProgress(0)
            setLogoutFlag(() => true)
            setOperationArr([]);
            setResult(
              `Сумма операций равна ${operationArr.reduce(
                (sum, element) => sum + element.price * element.qty,
                0
              ).toFixed(2)} рублей`
            );
            setTimeout(() => {
              console.log("LOGOUT ", logoutRef.current);
              if (logoutRef.current) {
                setResult("");
                setError("");

                cookies.remove("user", { domain: ".vakio.ru" })
                cookies.remove("accessToken", { domain: ".vakio.ru" })
                cookies.remove("refreshToken", { domain: ".vakio.ru" })
                cookies.remove("user")
                cookies.remove("accessToken")
                cookies.remove("refreshToken")
                userStore.logout();
              }

            }, 5000);
          },
          () => {
            setError("Что-то пошло не так!");
          }
        );
    } else {
    }
  };
  const noLogout = () => {
    console.log("NO LOGOUT");
    setLogoutFlag(false);
    setResult("");
    setError("");
    setProgress(0)
  }
  return (
    <div className="main_body">
      <div className="main__content" onClick={() => setFocus(0)}>
        <div
          className="main__content__search"
          onClick={(e) => e.stopPropagation()}
        >
          <Search
            setTargetOperation={setTargetOperation}
            operationArr={operationArr}
            setOperationArr={setOperationArr}
            targetOperation={targetOperation}
            value={value}
            setFocusSelect={setFocusSelect}
            setValue={setValue}
            focus={focus}
            noLogout={noLogout}
            setFocus={setFocus}
            setErrorOperation={setErrorOperation}
            setErrorOperationFeature={setErrorOperationFeature}
          />
        </div>
        {/* <p className="main__content__error">{error}</p> */}
        <div
          className="main__content__date"
        >
          <label style={{ marginRight: 5, fontSize: 15 }}>
            Дата учета
          </label>
          <DatePicker
            allowClear={false}
            value={date !== null ? date : undefined}
            onChange={setDate}
            format="DD-MM-YYYY"
            disabledDate={(current) => {
              const today = moment().toDate();
              today.setHours(23);
              today.setMinutes(59);
              return today <= current
            }}
          />
        </div>
        <div className="main__content__list" onClick={() => setFocus(0)}>

          <div className="operations_title">Операции</div>
          <OperationList
            updateFeature={updateFeature}
            operationArr={operationArr}
            deleteOperationFromArr={deleteOperationFromArr}
            focus={focus}
            setFocus={setFocus}
            updateComment={updateComment}
            error={error}
            result={result}
            handler={updateQtyKeyboard}
            noLogout={noLogout}
            progress={progress}
            setProgress={setProgress}
            errorOperation={errorOperation}
            errorOperationFeature={errorOperationFeature}
          />
          <div className="main__bottom_bar" onClick={() => setFocus(0)}>
            <Button
              style={{ width: 180, height: 40, fontSize: 16 }}
              onClick={allowItems}

            >
              Закончить смену
            </Button>

          </div>
        </div>

      </div>
      {/* <div className="main__right_bar">
        <div className="right_bar__keyboard">
          <Keyboard handler={updateQty} />
        </div>
        <div className="service_button">


          <Button
            style={{ width: 200, height: 50, fontSize: 18 }}
            onClick={() => userStore.logout()}
            icon={<LogoutOutlined />}
          >
            Выход
          </Button>
        </div>
      </div> */}

    </div>
  );
};

export default observer(MainPage);
