import { makeAutoObservable } from "mobx";
import axios from "axios";
import { REFRESH_URL } from "../http";
import UserService from "../services/UserService";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export default class UserStore {
  isAuth = false;
  doneAuth = false;
  isLoading = false;
  users = [];

  constructor() {
    this.user = {};

    makeAutoObservable(this);
  }

  deleteUserFromList(deletedUser) {
    const userIndex = this.users.findIndex(
      (user) => user.id === deletedUser.id
    );
    this.users.splice(userIndex, 1);
  }
  setAuth(bool) {
    this.isAuth = bool;
  }
  setDoneAuth(bool) {
    this.doneAuth = bool;
  }
  setUser(user) {
    if (user?.capabilities?.includes("super_admin")) {
      user.capabilities = user.capabilities + ",f_editor";
    }
    this.user = { ...user };
  }
  setUsers(users) {
    this.users = users;
  }


  setLoading(bool) {
    this.isLoading = bool;
  }
  async loginCookie(user, access_token, refresh_token) {
    localStorage.setItem("accessToken", access_token);
    localStorage.setItem("refreshToken", refresh_token);
    localStorage.setItem("user", user);
    // this.setUser(response.data);
    this.setAuth(true);
    this.setDoneAuth(true);
    this.setUser(user);
  }
  async login(login, password) {
    try {
      const response = await UserService.login(login, password);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);

      cookies.set('accessToken', (response.data.access_token), { domain: '.vakio.ru' });
      cookies.set('refreshToken', (response.data.refresh_token), { domain: '.vakio.ru' });
      // this.setUser(response.data);
      this.setDoneAuth(true);
      console.log(response.data);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async getUsers() {
    try {
      const response = await UserService.getUsers();
      // this.setUser(response.data);
      this.setUsers(response.data.users);
      console.log(response.data);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async updateUser(user) {
    try {
      const response = await UserService.updateUser(user);
      // this.setUser(response.data);
      //this.setUsers(response.data.users);
      console.log(response);
      return response
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async deleteUser(user_id) {
    try {
      const response = await UserService.deleteUser(user_id);
      // this.setUser(response.data);
      //this.setUsers(response.data.users);
      console.log(response);
      return response
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async createUser(user) {
    try {
      const response = await UserService.createUser(user);
      // this.setUser(response.data);
      //this.setUsers(response.data.users);
      console.log(response);
      return response
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async updatePassword(password, password_confirmation) {
    try {
      const response = await UserService.updatePassword(password, password_confirmation);
      console.log(response.data);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }
  async login_test(login, password) {
    let req = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ id: 1, login: login });
      }, 1000);
    });
    const result = await req;
    this.setUser(result);
    cookies.set('user', result, { domain: '.vakio.ru' });
  }

  // async updateUser(user) {
  //   try {
  //     const response = await UserService.updateUser(user);
  //     this.setUser(response.data.user);
  //     console.log("update user", user);
  //   } catch (e) {
  //     console.log(e.response);
  //     throw e.response;
  //   }
  // }
  async getUser() {
    try {
      const response = await UserService.getUser();
      this.setUser(response.data.user);
      console.log("update user", response.data.user);
    } catch (e) {
      console.log(e.response);
      throw e.response;
    }
  }

  async logout() {
    try {
      //await UserService.logout(refreshToken);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
      this.setAuth(false);
      this.setDoneAuth(false);
      this.setUser({});
    } catch (e) {
      console.log(e);
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.get(REFRESH_URL, {
        withCredentials: true,
        headers: {
          refresh: refreshToken,
        },
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      this.setUser(response.data.user);
      this.setAuth(true);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  }
}
