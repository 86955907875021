import axios from "axios";
export const API_URL = "https://production.vakio.ru";
export const API_AUTH_URL = "https://auth.vakio.ru";
export const REFRESH_URL =
  "https://auth.vakio.ru/oauth/token";

export const $api = axios.create({
  //withCredentials: true,
  baseURL: API_URL,
});
export const $apiAuth = axios.create({
  //withCredentials: true,
  baseURL: API_AUTH_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;

  return config;
});
$apiAuth.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;

  return config;
});

$api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/user/refresh`, {
          withCredentials: true,
        });
        localStorage.setItem("accessToken", response.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) { }
    }
    throw error;
  }
);
$apiAuth.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/user/refresh`, {
          withCredentials: true,
        });
        localStorage.setItem("accessToken", response.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) { }
    }
    throw error;
  }
);


