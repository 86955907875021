import React, { useContext, useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { Context } from "../..";
import { notification } from 'antd';
import "./Search.scss";
import Cookies from 'universal-cookie';
import moment from "moment";

const cookies = new Cookies();

const Search = ({
  setTargetOperation,
  operationArr,
  setOperationArr,
  targetOperation,
  value,
  setValue,
  focus,
  setFocus,
  noLogout,
  setErrorOperation,
  setErrorOperationFeature
}) => {
  const [selectValue, setSelectValue] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);
  const { operationStore } = useContext(Context);
  const { userStore } = useContext(Context);
  let myRef = useRef(null);
  useEffect(() => {
    if (focus === 100) {
      myRef.focus();
      setSelectValue(value.label);
      loadDefaultOptions(value.label);
    }
  }, [value]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setOutput(userValue);
  //   }, 600);
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [value]);

  const openNotification = () => {
    notification['warning']({
      duration: 3,
      message: 'Ошибка!',
      description:
        'выбранная операция уже добавлена!',

    });
  };
  const addOperationToList = (operation) => {
    noLogout();
    // Если пустой обьект возврат
    if (Object.keys(operation).length === 0) return;
    // Ищем полную информацию о операции из данных с сервера

    const newOperation = operationStore.operations.find(
      (e) => e.code === operation?.value
    );
    // Проверяем есть ли такая операция, чтобы не было повторов, если это не временная операция 
    const index = operationArr.find((e) => e.code === operation?.value && e.code !== "01.11" && e.code !== "04.11");
    console.log(index, operationStore.operations);
    if (!index && newOperation) {
      setErrorOperation([])
      setErrorOperationFeature([])
      // Если операции нет, добавляем с количеством 0
      newOperation.comment = ""
      newOperation.key = moment().unix()
      newOperation.feature = null
      newOperation.delete_mark = 2
      newOperation.qty = "";
      setOperationArr([...operationArr, newOperation]);
      setTimeout(() => {
        setFocus(newOperation.id + 1);

      }, 300);
    } else {
      console.log("OPERATION HAVE");
      setOperationArr([...operationArr]);
      setTimeout(() => {
        setFocus(100);
        openNotification();
      }, 300);
    }

    setValue({ value: null, label: null });
    setSelectValue("");
    setFocus(0);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #cccccc",
      padding: 20,
      width: "100%",
    }),
    control: (provided, state) => ({
      ...provided,
      overflow: "hidden",
      whiteSpace: "nowrap",
      backgroundColor: state.isFocused ? "aliceblue" : "white",
      height: 55,
      border: state.isFocused ? "1px solid #1e81df" : "1px solid #cccccc",
    }),
  };

  const promiseOptions = async (value) => {

    const response = await operationStore
      .getOperations(value, 15, 1)
      .catch((e) => {
        cookies.remove("user", { domain: ".vakio.ru" })
        cookies.remove("accessToken", { domain: ".vakio.ru" })
        cookies.remove("refreshToken", { domain: ".vakio.ru" })
        cookies.remove("user")
        cookies.remove("accessToken")
        cookies.remove("refreshToken")
        userStore.logout();
      })
      .finally(() => { });
    console.log("response");
    return operationStore.operations.map((item) => {
      return {
        value: item.code,
        label: item.code + " | " + item.title,
      };
    });


    //setOperationArr(operationStore.operations);
  };
  const loadDefaultOptions = async (inputValue) => {
    const result = await promiseOptions(inputValue);
    setDefaultOptions([...result]);
  };

  return (
    <div className="search"  >
      <div className="search__inp" >
        <AsyncSelect
          styles={customStyles}
          noOptionsMessage={() => "Операция не найдена"}
          loadingMessage={() => "Поиск..."}
          loadOptions={promiseOptions}
          onChange={addOperationToList}
          onBlur={() => {
            setSelectValue(value.label);
          }}

          defaultOptions={defaultOptions}
          ref={(ref) => (myRef = ref)}
          onFocus={() => { setFocus(100); }}
          menuIsOpen={focus === 100}
          value={value.value === null ? null : value}
          inputValue={selectValue === null ? "" : selectValue}
          placeholder="Выберите операцию"
          onInputChange={(newValue, action) => {
            if (action.action === "input-change") {
              setSelectValue(newValue);


            }
          }}
        />
      </div>
      {/*<div className="search__button">
                <DefaultButton
                    fontSize={20}
                    title="Добавить"
                    onClick={() => addOperationToList(targetOperation)}
                />
    </div>*/}
    </div >
  );
};

export default Search;
