import React from "react";
import "./CartPreview.scss";


const CartPreview = () => {

  return (
    <div className="header__cart cart-preview">

    </div>
  );
}

export default CartPreview;