import React, { useState, useEffect } from "react";
import { Card, Typography, Table, Modal, Button, Input, message, Select, Form, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
import "../../styles/Card.scss";
import "./UsersPage.scss"
import { useContext } from "react";
import { Context } from "../..";
export default function UsersPage() {
  const { userStore } = useContext(Context);

  const [reloadPage, setReloadPage] = useState(true);
  const [editUser, setEditUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [addModalVis, setAddModalVis] = useState(false);
  const [createNew, setCreateNew] = useState(true);
  const [tableUsers, setTableUsers] = useState([]);
  const [phoneError, setPhoneError] = useState(true);
  const [updatedIndex, setUpdatedIndex] = useState(0);

  const [form] = Form.useForm();

  const DynamicPhone = (props) => {
    const cellphoneMask = "(00) 0 0000-0000";
    const phoneMask = "+7 (000) 000-00-00";

    // always memoize dynamic masks
    const mask = React.useMemo(
      () => [
        {
          mask: cellphoneMask,
          lazy: false,
        },
        {
          mask: phoneMask,
          lazy: false,
        },
      ],
      []
    );

    return (
      <MaskedInput
        {...props}
        mask={mask}
        maskOptions={{
          dispatch: function (appended, dynamicMasked) {
            const isCellPhone = dynamicMasked.unmaskedValue[2] === "9";
            return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
          },
        }}
      />
    );
  };

  useEffect(() => {
    // if (Object.keys(editUser).length === 0) return;
    if (editUser === undefined) return;
    if (editUser.email === "") {
      form.setFieldsValue(editUser);
      setCreateNew(true);
    } else {
      // if (editUser_.capabilities != "")
      //   editUser_.capabilities = editUser_.capabilities.split(", ").map(cap => { return { value: unroles[cap], label: cap } })

      form.setFieldsValue(editUser);
      setCreateNew(false);
    }
    setAddModalVis(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser]);

  useEffect(() => {
    setPhoneError(false);
    const getAllUsers = async () => {
      setIsLoading(true);
      userStore.getUsers().then(() => {
        setIsLoading(false);
        setTableUsers(userStore.users)
      })

    }
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadPage]);

  function generatePassword() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    form.setFieldsValue({
      password: retVal,
    });
  }
  const converter = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
    'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
    'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
    'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
    'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
    'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
    'э': 'e', 'ю': 'yu', 'я': 'ya',

    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
    'Е': 'E', 'Ё': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
    'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
    'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
    'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch',
    'Ш': 'Sh', 'Щ': 'Sch', 'Ь': '', 'Ы': 'Y', 'Ъ': '',
    'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
  };
  function sendForm() {
    form
      .validateFields()
      .then((values) => {
        setPhoneError(false);
        const copy = Object.assign({}, values);
        console.log(copy);
        if (copy.phone !== "+7 (___) ___-__-__" && copy.phone !== "") {

          copy.phone = "+" + copy.phone
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .split(" ")
            .join("");
          console.log(copy.phone);
          if (copy.phone === "+7" || copy.phone.length < 12) {
            setPhoneError(true);
            return;
          }
        }
        if (copy.email === "") {
          const splitName = copy.name.split(" ");
          let emailRu = splitName[0];
          for (let i = 0; i < emailRu.length; ++i) {
            if (converter[emailRu[i]] == undefined) {
              copy.email += emailRu[i].toLowerCase();
            } else {
              copy.email += converter[emailRu[i]].toLowerCase();
            }
          }
          copy.email += "@vakio.ru"
        }
        if (createNew) {

          userStore.createUser({ ...copy }).then(response => {
            console.log(response);

            setAddModalVis(false);
            form.resetFields();
            setTableUsers([...tableUsers, response.data])

          })
          // UserService.createUser(copy).then((resposne) => {
          //   if (resposne.status === 500) {
          //     throw "error"
          //   }
          //   setAddModalVis(false);
          //   form.resetFields();
          //   message.success("Пользователь добавлен");
          //   setReloadPage(!reloadPage);
          //   setUpdatedIndex(editUser.id)
          //   setTimeout(() => {
          //     setUpdatedIndex(0)
          //   }, 1000)

          // }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
        } else {
          userStore.updateUser({ ...copy, id: editUser.id }).then(response => {
            setAddModalVis(false);
            form.resetFields();
            message.success("Пользователь " + editUser.name + " обновлен!");
            setReloadPage(!reloadPage);
            setUpdatedIndex(editUser.id)
            setTableUsers(tableUsers.map(user => {
              if (user.id === editUser.id) {
                return { ...user, ...copy }
              }
              return user
            }))
            setTimeout(() => {
              setUpdatedIndex(0)
            }, 1000)
          })
          // UserService.updateUser(editUser.id, copy).then((resposne) => {
          //   setAddModalVis(false);
          //   form.resetFields();
          //   message.success("Пользователь обновлен");
          //   setReloadPage(!reloadPage);
          //   setUpdatedIndex(editUser.id)
          //   setTimeout(() => {
          //     setUpdatedIndex(0)
          //   }, 1000)
          // }).catch(error => message.error("Не удалось обновить информацию пользователя!"))
        }


      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  }

  const roles = {
    "f_user": "Исполнитель",
    "f_editor": "Руководитель",
  }


  const deleteUser = (full) => {
    userStore.deleteUser(full.id).then(response => {
      setTableUsers(tableUsers.filter(user_ => user_.id !== full.id))
      message.success(`Пользователь ${full.name} успешно удалён`)
    }).catch(error => message.error("Не удалось удалить пользователя"))
    // UserService.deleteUser(id).then(response => {
    //   message.error("Пользователь успешно удалён")
    // }).catch(error => message.error("Не удалось удалить пользователя"))
  }
  return (
    <div>
      <Card
        title={<Typography.Title level={3}>Аккаунты</Typography.Title>}
        style={{ marginBottom: 20 }}
        extra={
          <Button
            type="default"
            // shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditUser({
                capabilities: "f_user",
                email: "",
                name: "",
                phone: "",
                title: "",
              });
            }}
          >
            {/* {window.screen.width > 450 && "Добавить пользователя"} */}
            Добавить
          </Button>
        }
        bordered={false}
        className="card"
      >
        <Table
          pagination={false}
          // pagination={{
          //   defaultCurrent: 1,
          //   total: 500,
          //   pageSize: 500,
          // }}
          rowClassName={(record, index) => record.id === updatedIndex ? 'table-row start-animate ' : 'table-row'}
          bordered={true}
          loading={isLoading}
          columns={[
            {
              title: "Имя",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "E-mail",
              dataIndex: "email",
              key: "email",
            },
            {
              title: "Телефон",
              dataIndex: "phone",
              key: "phone",
            },
            {
              title: "Роль",
              dataIndex: "capabilities",
              key: "capabilities",
              render: (text) => (<div>{roles[text]}</div>)
            },

            {
              title: "",
              dataIndex: "action",
              key: "action",
              render: (_, full) => (
                <Typography.Link
                  href="#"
                  onClick={(e) => {
                    setAddModalVis(true)
                    e.preventDefault();
                    setEditUser(full);
                  }}
                >
                  <EditOutlined />
                </Typography.Link>
              ),
            },
            {
              title: "",
              dataIndex: "delete",
              key: "delete",
              render: (_, full) => (
                <Popconfirm title={"Удалить пользователя " + full.name + "?"} placement="topLeft" onConfirm={() => deleteUser(full)} okText="Да" cancelText="Нет">

                  <Typography.Link
                    href="#"
                    onClick={(e) => {

                      e.preventDefault();

                    }}
                  >
                    <DeleteOutlined />
                  </Typography.Link>
                </Popconfirm>
              ),
            },
          ]}
          dataSource={tableUsers ? tableUsers : []}
          scroll={{ x: 300 }}
          rowKey="id"
        />
      </Card>


      <Modal
        title={
          <Typography.Title level={4}>{createNew ? "Добавить" : "Обновить"} пользователя</Typography.Title>
        }
        centered
        visible={addModalVis}
        onOk={sendForm}
        okText={createNew ? "Добавить" : "Обновить"}
        cancelText={"Отмена"}
        onCancel={() => setAddModalVis(false)}
        closable={false}
        destroyOnClose
        forceRender
        width={window.screen.width < 450 ? "100%" : 450}
        // zIndex={10000}
        bodyStyle={{ paddingBottom: 16 }}
      >
        <Form
          name="wrap"
          // labelCol={{ flex: "110px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          form={form}
          initialValues={editUser}
          layout="vertical"
        >


          <Form.Item
            label="Имя пользователя"
            required
            name="name"
            rules={[
              {
                required: true,
                message: "Поле обязательно к заполнению",
              },
            ]}
          >
            <Input placeholder="Введите имя" className="settings__input" />
          </Form.Item>

          <Form.Item
            label="E-mail"

            name="email"
            rules={[
              {

                message: "Некорректный E-mail",
                type: "email",
              },
            ]}
          >
            <Input placeholder="Введите e-mail" className="settings__input" />
          </Form.Item>


          <Form.Item
            label="Телефон"
            //required
            name="phone"
            validateStatus={phoneError ? "error" : undefined}
            help={phoneError ? "Некорректный телефон!" : undefined}
            rules={[
              {
                required: false,
                message: "Некорректный телефон",
                type: "string",
              },
            ]}
          >

            <DynamicPhone />
          </Form.Item>

          <Form.Item
            label="Пароль"
            required
            name="password"
            rules={[
              {
                required: true,
                message: "Минимальная длина пароля: 8 символов",
              },
            ]}
          >
            <Input
              placeholder="Введите пароль"
              suffix={
                <Button type="default" size="small" onClick={generatePassword}>
                  Сгенерировать
                </Button>
              }
              className="settings__input"
            />
          </Form.Item>

          <Form.Item label="Роль" required name="capabilities">
            <Select className="settings__input" onChange={() => console.log(1)}>
              <Select.Option value="f_editor">Руководитель</Select.Option>
              <Select.Option value="f_user">Исполнитель</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
