import { Button, Input, message, Popconfirm, Select, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import QrReader from 'react-web-qr-reader';
import { Context } from '../..';
import QrCodeReader from './components/QrCodeReader/QrCodeReader';
import "./WorkPage.scss"

const WorkPage = () => {
    const [scanQrCode, setScanQrCode] = useState(false);
    const [error, setError] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [targetOperation, setTargetOperation] = useState(null)
    const [operations, setOperations] = useState([])
    const { operationStore } = useContext(Context);
    const { userStore } = useContext(Context);
    const [createdOperation, setCreatedOperation] = useState(null)
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [timerStr, setTimerStr] = useState("");
    const [serialNumber, setSerialNumber] = useState(null);
    const [workIsStart, setWorkIsStart] = useState(false);
    const [notEndedOperations, setNotEndedOperations] = useState([])
    const [isNotEnded, setIsNotEnded] = useState(false);


    const setNotEndedOperation = (operation) => {
        console.log("Set operation ", operation);
        setTargetOperation(operation);
        setCreatedOperation(operation);
        setSerialNumber(operation.qrcode);
        setStartTime(moment(operation.start))
        setWorkIsStart(true);
        setIsNotEnded(true);
    }
    useEffect(() => {
        operationStore.getWatchOperations([
            {
                "field": "user",
                "comparison": "=",
                "value": userStore.user.id
            },
            {
                "field": "end",
                "comparison": "=",
                "value": null
            }
        ], 100, 1).then(response => {
            setNotEndedOperations(response.data.result);
            if (response.data.result.length > 0) {
                const operation = response.data.result[0];
                console.log("Set not ended operation", operation);
                setNotEndedOperation(operation);

            }
            console.log("Get operatons user", response);
        })
    }, [])
    const handleScan = (data) => {
        setSerialNumber(data.data)
        setScanQrCode(false);
    }
    const handleError = (err) => {
        setError(err.toString());
        console.error("error!", err)
    }
    const startWorkOperation = () => {
        setStartTime(moment())
        operationStore.createWatchOperation(
            {
                "ref": targetOperation.ref,
                "code": targetOperation.code,
                "user": userStore.user.id,
                "title": targetOperation.title,
                "qrcode": serialNumber,
                "start": moment().format("YYYY-MM-DD HH:mm:ss"),
                "delete_mark": 1,
                "comment": null
            }
        ).then((response) => {
            if (response.status !== 200) {
                throw "Error response"
            }
            setCreatedOperation(response.data);
            setWorkIsStart(true);
            console.log("Create response", response);
        }).catch(err => {
            console.log("Create err", err);
            message.error("Не удалось начать работу!");
        })
    }
    const toggleQrCodeFlag = () => {
        console.log("Toggle!", !scanQrCode);
        setScanQrCode(!scanQrCode);
        // if (!scanQrCode) {
        //     startWorkOperation();
        // }
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            operationStore.getOperationsWork(searchValue, 20, 1).then((response) => {
                console.log("Operations", response.map(item => {
                    return { value: item.id, label: item.code + " | " + item.title }
                }));
                setOperations(response)
            })
        }, 300)
        return () => clearTimeout(timeout);
    }, [searchValue])

    const onSearch = (value) => {
        setSearchValue(value)
    }

    const onSelectOperation = (operation) => {
        console.log(operations.find(item => item.id === operation));
        setTargetOperation(operations.find(item => item.id === operation));
    }

    const getTimeParam = (param) => {
        if (param < 10) return "0" + param;
        else return param.toString();
    }
    const getTime = () => {
        const diff = moment().diff(startTime);
        const hours = moment().diff(startTime, 'hours');
        const minutes = moment().diff(startTime, 'minutes');
        const seconds = moment().diff(startTime, 'seconds');
        var mom = moment(getTimeParam(hours % 24) + ":" + getTimeParam(minutes % 60) + ":" + getTimeParam(seconds % 60), 'HH:mm:ss');
        setTimerStr(mom.format("HH:mm:ss"))

    };
    useEffect(() => {
        console.log("STart!!!");
        const interval = setInterval(() => getTime(), 1000);

        return () => clearInterval(interval);
    }, [startTime, workIsStart]);
    const endWorkOperation = () => {
        setEndTime(moment())
        operationStore.updateWatchOperation({
            id: createdOperation.id,
            end: moment().format("YYYY-MM-DD HH:mm:ss"),
        }).then(response => {
            if (response.status !== 200) {
                throw "Error response"
            }
            //setTargetOperation(null);
            setSerialNumber("")
            setWorkIsStart(false);
            console.log("response ", response);
            message.success(`Успешно заверщено! Время: ${moment().diff(startTime, 'hours') % 24} часов ${moment().diff(startTime, 'minutes') % 60} минут`)
            setIsNotEnded(false);
            if (isNotEnded) {
                const notEndedOperations_ = notEndedOperations.filter(item => item.id !== createdOperation.id);
                setNotEndedOperations(notEndedOperations_);
                if (notEndedOperations_.length > 0) {
                    setNotEndedOperation(notEndedOperations_[0]);
                }
            }

        }).catch(err => {
            console.log(err);
            message.error("Не удалось завершить работу!")

        })

    }

    const startWork = () => {
        if (serialNumber.length > 0 && targetOperation != null) {
            startWorkOperation();
        }
    }
    const resetWork = () => {
        operationStore.deleteWatchOperation(createdOperation.id).then((response) => {
            console.log("RESPONSE", response);
            if (response.status !== 204) {
                throw "Response error"
            }
            setSerialNumber("");
            //setTargetOperation(null);
            setWorkIsStart(false);
            message.success("Операция сброшена.")
            setIsNotEnded(false);
            if (isNotEnded) {
                const notEndedOperations_ = notEndedOperations.filter(item => item.id !== createdOperation.id);
                setNotEndedOperations(notEndedOperations_);
                if (notEndedOperations_.length > 0) {
                    setNotEndedOperation(notEndedOperations_[0]);
                }
            }
        }).catch(err => {
            console.log("ERROR", err);
            message.error("Не удалось сбросить операцию!")
        })
    }
    return (
        <div className='work'>
            {isNotEnded && <Typography.Title level={5}>Незавершенная ранее операция</Typography.Title>}
            <div className='work__card'>
                <div className='work__card__operation'>
                    <div className='work__card__operation__label grey'>Выберите операцию</div>
                    <Select
                        className='work__card__operation__select'
                        showSearch
                        disabled={workIsStart}
                        value={targetOperation && { value: targetOperation.id, label: targetOperation.code + " | " + targetOperation.title }}
                        autoClearSearchValue={false}
                        placeholder="Операция..."
                        onSearch={onSearch}
                        onChange={onSelectOperation}
                        options={operations.map(item => {
                            return { value: item.id, label: item.code + " | " + item.title }
                        })}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </div>

                <div className='work__card__serial'>
                    <div className='work__card__serial__scan'>
                        <Button style={{ width: "100%", height: 60, backgroundColor: "#47677E", border: "none", borderRadius: 12 }} onClick={toggleQrCodeFlag} type="primary" disabled={workIsStart}>
                            Сканировать QR-код
                        </Button>
                    </div>
                    <Input
                        disabled={workIsStart}
                        className='work__card__serial__inp'
                        placeholder='Серийный номер'
                        onChange={(e) => setSerialNumber(e.target.value)}
                        value={serialNumber}
                    />
                </div>
                {!workIsStart && <div className='work__card__start'>
                    <Button style={{ width: "100%", height: 50, backgroundColor: "#81A9B0", border: "none", borderRadius: 12, color: "white" }} disabled={!(serialNumber?.length > 0 && targetOperation != null)} onClick={startWork}>
                        Начать
                    </Button>
                </div>}

                {workIsStart &&

                    <div className='work__card__start'>

                        <Popconfirm
                            placement="topRight"
                            title="Сбросить операцию?"
                            onConfirm={resetWork}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button style={{ width: "100%", height: 50, backgroundColor: "#E71044", border: "none", borderRadius: 12, color: "white" }} type='danger' >
                                Сбросить
                            </Button>
                        </Popconfirm>
                    </div>
                }

            </div >
            {workIsStart && <div className='work__result'>

                {timerStr != "Invalid date" && <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 10 }}> Время работы: </div><div className='work__result__param'>{timerStr}</div>
                </div>}
                <div style={{ marginTop: 10 }}>
                    <div style={{ marginRight: 10 }}> {`Операция: `}</div><div className='work__result__param'> {targetOperation?.code} | {targetOperation?.title}</div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <div style={{ marginRight: 10 }}>{`Серийный номер: `}</div> <div className='work__result__param'>{serialNumber}</div>
                </div>
                <div></div>
                <div className='work__card__end'>
                    <Button
                        style={{ width: "100%", height: 60, backgroundColor: "#59b84F", border: "none", borderRadius: 12 }}
                        className='work__card__end__button'
                        onClick={endWorkOperation}
                        type="primary">
                        Завершить
                    </Button>
                </div>
            </div>}
            <QrCodeReader handleError={handleError} handleScan={handleScan} showViewFinder={scanQrCode} setShowViewFinder={setScanQrCode} />
        </div>
    );
};

export default WorkPage;