import { $api, $apiAuth } from "../http";

const client_id = "960d6b50-5ad5-4c26-9fe9-797fe4809b6c";
const client_secret = "tl6piAtLny5ppzapzFVVqb22z6Jg0b3SKN3WFbzb";
const grant_type = "password";
export default class AuthService {
  static async login(username, password) {
    return $apiAuth.post("/oauth/token", {
      grant_type,
      client_id,
      client_secret,
      username,
      password,
    });
  }
  static async getUsers() {
    return $apiAuth.get("/api/users");
  }
  static async refreshPassword(email) {
    return $apiAuth.post("/user/password", { email });
  }

  static async getUser(user) {
    return $apiAuth.get("/api/user/get", user);
  }
  static async createUser(user) {
    return $api.post("/api/user", user);
  }
  static async updateUser(user) {
    return $api.put("/api/user/" + user.id, user);
  }
  static async deleteUser(user_id) {
    return $api.delete("/api/user/" + user_id);
  }

  static async registration(user) {
    return $apiAuth.post("/user/registration", user);
  }

  static async updatePassword(password, password_confirmation) {
    return $apiAuth.post("/api/user/reset_password", { password, password_confirmation });
  }

  static async logout(refreshToken) {
    return $apiAuth.post("/user/logout", {
      refreshToken: refreshToken,
    });
  }
}
