import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Context } from "../..";
// import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "./NewSearch.scss";
import { Card, DatePicker, Input } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import moment from "moment";
registerLocale("ru", ru);

const NewSearch = ({ viewMode, groupMode }) => {
  const { userStore } = useContext(Context);
  const editor_search = [
    {
      field: "code",
      comparison: "like",
      value: "",
    },
    {
      field: "user_id",
      comparison: "=",
      value: userStore.user.id,
    },
    {
      field: "title",
      comparison: "like",
      value: "",
    },
    {
      field: "dateStart",
      comparison: ">",
      value: moment(new Date()).clone().startOf('month').format("YYYY-MM-DD")
    },
    {
      field: "dateEnd",
      comparison: "<",
      value: moment(new Date()).format("YYYY-MM-DD")
    },
  ];

  const [loading, setLoading] = useState(false);
  const { operationStore } = useContext(Context);

  const [focus, setFocus] = useState(0);
  const [defaultUser, setDefaulUser] = useState({ value: userStore.user.id, label: userStore.user.name });

  const [search, setSearch] = useState(
    editor_search
    // userStore.user.capabilities !== "f_user" ? editor_search : user_search
  );
  const [usersSelect, setUsersSelect] = useState([]);

  useEffect(() => {
    setSearch(editor_search);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      padding: 20,
      width: "100%",
    }),
    control: (provided, state) => ({
      ...provided,
      borderSizing: "border-box",
      outline: "none",
      backgroundColor: state.isFocused ? "aliceblue" : "white",
      height: 39,
      borderRadius: 0,
      border: "none",
      borderTop: "none",
      borderRight: "none",
      boxShadow: "none",
      "&:hover": {

        borderTop: "none",
        borderRight: "none",
      },
    }),
  };
  useEffect(() => {
    async function getUsers() {
      let response = await userStore.getUsers().then(() => {
        const users = userStore.users.map((user) => {
          return { value: user.id, label: user.name };
        });
        users.unshift({ value: "", label: "Все" });
        setUsersSelect(users);
      });
      await searchResult(search).then(() => { });
    }
    getUsers();
  }, []);

  useEffect(async () => {
    async function getUsers() {
      let response = await userStore.getUsers().then(() => {
        const users = userStore.users.map((user) => {
          return { value: user.id, label: user.name };
        });
        users.unshift({ value: "", label: "Все" });
        setUsersSelect([...users]);
      });
    }
    async function getUsersEditor() {
      let response = await operationStore.getUsersAllow().then(() => {
        const users = userStore.users
          .filter((user) => {
            if (operationStore.usersAllow.includes(user.id.toString()))
              return { value: user.id, label: user.name };
          })
          .map((user) => {
            return { value: user.id, label: user.name };
          });
        users.unshift({ value: "", label: "Все" });
        setUsersSelect([...users]);

        // getUsers();
      });
    }
    if (viewMode) getUsersEditor();
    else {
      getUsers();
    }
    await searchResult(search).then(() => { });
  }, [viewMode, groupMode]);

  const titles = {
    code: "Код",
    user_id: "Имя",
    title: "Операция",
    dateStart: "Начало",
    dateEnd: "Конец",
  };
  const searchWidth = {
    code: "15%",
    user_id: "25%",
    title: "35%",
    dateStart: "20%",
    dateEnd: "20%",
  };
  const updateValue = (newValue, key) => {
    const index = search.findIndex((element) => element.field === key);
    search[index].value = newValue;
    setSearch(() => [...search]);
    searchResult(search);
  };
  // const updateСomparison = (newValue, key) => {
  //   const index = search.findIndex((element) => element.field === key);
  //   search[index].comparison = newValue;
  //   setSearch(() => [...search]);

  // };

  const searchResult = async (search) => {
    setLoading(true);

    const searchF = JSON.parse(JSON.stringify(search));

    let filters = searchF.filter((element) => {
      if (element.value !== "" && element.value !== null) return element;
    });

    const dateStartInx = filters.findIndex(
      (filter) => filter?.field === "dateStart"
    );
    const dateEndInx = filters.findIndex(
      (filter) => filter?.field === "dateEnd"
    );

    if (dateStartInx > -1 && dateEndInx < 0) {
      filters[dateStartInx].field = "created_at";
      filters[dateStartInx].comparison = ">";
      filters[dateStartInx].value = moment(filters[dateStartInx].value).format(
        "YYYY-MM-DD"
      );
    }
    if (dateEndInx > -1 && dateStartInx < 0) {
      filters[dateEndInx].field = "created_at";
      filters[dateEndInx].comparison = "<";
      filters[dateEndInx].value = moment(filters[dateEndInx].value).add("days", 1).format(
        "YYYY-MM-DD"
      );
    }
    if (dateStartInx > -1 && dateEndInx > -1) {
      if (filters[dateEndInx].value === filters[dateStartInx].value) {
        filters[dateEndInx].field = "created_at";
        filters[dateEndInx].comparison = "<";
        filters[dateEndInx].value = moment(filters[dateEndInx].value).add(1, 'day').format(
          "YYYY-MM-DD"
        );
        filters[dateStartInx].field = "created_at";
        filters[dateStartInx].comparison = ">";
        filters[dateStartInx].value = moment(
          filters[dateStartInx].value
        ).subtract(1, 'day').format("YYYY-MM-DD");
      } else {
        filters[dateEndInx].field = "created_at";
        filters[dateEndInx].comparison = "<";
        filters[dateEndInx].value = moment(filters[dateEndInx].value)
          .add("days", 1)
          .format("YYYY-MM-DD");
        filters[dateStartInx].field = "created_at";
        filters[dateStartInx].comparison = ">";
        filters[dateStartInx].value = moment(
          filters[dateStartInx].value
        ).format("YYYY-MM-DD");
      }
    }
    if (viewMode) {
      filters.push({
        comparison: ">",
        field: "delete_mark",
        value: 0,
      });
    } else {
      filters.push({
        comparison: "<",
        field: "delete_mark",
        value: 2,
      });
    }
    operationStore.setFilters(filters);
    await operationStore.getOperationHistory(filters, operationStore.pageSize, 1, groupMode).then(() => {
      setLoading(false);
      operationStore.setCurrentPage(1);
    });
  };

  const setUserId = (name) => {
    const index = search.findIndex((element) => element.field === "user_id");
    search[index].value = name.value;
    setSearch(() => [...search]);
    searchResult(search);
  };
  //code title date user

  const setDateFilter = (date, field) => {
    const index = search.findIndex((element) => element.field === field);
    search[index].value = date;
    setSearch(() => [...search]);
    searchResult(search);
  };

  return (
    <Card style={{ marginBottom: 20 }} bordered={false} className="card">
      <div className="filter-field filter-field--no-margin">
        <div className="filter-field__item">
          <Input
            placeholder="Поиск по коду"
            size="large"
            onChange={(e) => updateValue(e.target.value, "code")}
            // onChange={e => setAddress(e.target.value)}
            style={{ marginTop: 5 }}

          />
        </div>

        {userStore.user.capabilities.includes("f_editor") && <div className="filter-field__item__select">
          <Select
            options={usersSelect}
            defaultValue={defaultUser}
            placeholder="Имя"
            styles={customStyles}
            onMenuOpen={() => setFocus(false)}
            onChange={setUserId}
          />
        </div>}
        <div className="filter-field__item">
          <Input
            placeholder="Поиск по названию"
            size="large"
            onChange={(e) => updateValue(e.target.value, "title")}
            //  onChange={e => setPhone(e.target.value)}
            style={{ marginTop: 5 }}

          />
        </div>
        <div className="filter-field__item__date">
          <DatePicker
            size="large"
            placeholder="Дата от"
            allowClear={true}
            value={moment(search.find(item => { console.log(item); return item.field === "dateStart" }).value).toString() === "Invalid date" ?
              undefined : moment(search.find(item => { console.log(item); return item.field === "dateStart" }).value)
            }
            onChange={(date) => {
              setDateFilter(date, "dateStart");
            }}
            style={{ marginTop: 10, width: "100%" }}
            format="YYYY-MM-DD"
          />
        </div>
        <div className="filter-field__item__date">
          <DatePicker
            size="large"
            placeholder="Дата до"
            allowClear={true}
            value={moment(search.find(item => { console.log(item); return item.field === "dateEnd" }).value).toString() === "Invalid date" ?
              undefined : moment(search.find(item => { console.log(item); return item.field === "dateEnd" }).value)}
            locale="ru"
            dateFormat="YYYY-MM-DD"
            //value={date}
            onChange={(date) => {
              setDateFilter(date, "dateEnd");
            }}
            style={{ marginTop: 10, width: "100%" }}

          />
        </div>
      </div>
    </Card>
  );
};

export default observer(NewSearch);
