import {
  LOGIN_ROUTE,
  MAIN_ROUTE,
  PROFILE_ROUTE,
  EDITOR_PAGE,
  SETTINGS_ROUTE,
  USERS_ROUTE,
  WORK_ROUTE,
  WORK_HISTORY_ROUTE,
} from "./utils/consts";
import MainPage from "./pages/MainPage";
import ProfilePage from "./pages/ProfilePage";
import EditorPage from "./pages/EditorPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import UsersPage from "./pages/UsersPage/UsersPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import WorkPage from "./pages/WorkPage/WorkPage";
import WorkHistoryPage from "./pages/WorkHistoryPage/WorkHistoryPage";

export const authRoutes = [
  {
    path: MAIN_ROUTE,
    Component: MainPage,
  },
  {
    path: USERS_ROUTE,
    Component: UsersPage,
  },
  {
    path: SETTINGS_ROUTE,
    Component: SettingsPage,
  },
  {
    path: WORK_ROUTE,
    Component: WorkPage,
  },
  {
    path: WORK_HISTORY_ROUTE,
    Component: WorkHistoryPage,
  },
  {
    path: EDITOR_PAGE,
    Component: EditorPage,
  },
];

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: LoginPage,
  },
];
