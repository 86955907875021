import React, { useState } from "react";
import "./DivInput.scss";
const DivInput = ({ value, focus, placeholder, align }) => {
  return (
    <div style={{ justifyContent: align ? align : null }} className={focus ? "div_input focused" : "div_input"}>
      {value.length > 0 ? (
        <p style={{ marginLeft: align ? align : 8 }}>{value}</p>
      ) : (
        <p style={{ marginLeft: 8, color: "#808080" }}>{placeholder}</p>
      )}
    </div>
  );
};

export default DivInput;
