import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import {
  HomeFilled,
  ArrowLeftOutlined,
  SettingFilled,
  LinkOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";

import logo from "./logo.svg";
import "./Sidebar.scss";
import { useContext } from "react";
import { Context } from "../..";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


export default function Sidebar(props) {
  const history = useHistory();
  const { userStore } = useContext(Context);
  const [collapsed, setCollapsed] = useState(true);
  const [defaultSelected, setDefaultSelected] = useState("user");
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  // console.log(location.pathname);
  const [nav, setNav] = useState(JSON.parse(localStorage.getItem("navigation") || "[]"))
  const [openKeys, setOpenKeys] = useState([]);
  const [isMobile, setIsMobile] = useState((window.screen.width) < 450)

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);

    setOpenKeys(keys);
    // if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //   setOpenKeys(keys);
    // } else {
    //   setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    // }
  };

  useEffect(() => {
    const host = window.location.hostname;
    if (host.includes("production.vakio")) {
      setOpenKeys(["portal_production"]);
    }
    if (host.includes("projects.vakio")) {
      setOpenKeys(["portal_projects"]);
    }
    if (host.includes("dealers.vakio")) {
      setOpenKeys(["portal_dealers"]);
    }
    if (window.location.pathname.includes("portal-services")) {
      setOpenKeys(["portal_service"]);
    }

  }, [])

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);
  const checkAccess = (roles) => {
    const userRoles = userStore.user.capabilities.split(",")
    let access = false;
    roles.forEach(role => {
      if (userRoles.includes(role)) {
        access = true;
      }
    });
    return access;
  }

  useEffect(() => {
    fetch("https://portal.vakio.ru/api/routes", {
      method: "GET",

      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("accessToken")
      }
    }).then((response) => response.json()).then(response => {
      console.log("RESPONSE ", response);
      localStorage.setItem("navigation", JSON.stringify(response));
      setNav(response);
      setLoading(false);
    })
  }, [])



  useEffect(() => {
    setDefaultSelected(location.pathname.slice(1));
  }, [location.pathname]);

  return (
    <Layout.Sider
      className="sidebar"
      breakpoint="lg"
      collapsedWidth={window.screen.width < 450 ? 0 : undefined}
      collapsible={window.innerWidth > 450 ? true : false}
      width={window.innerWidth > 1400 || window.innerWidth < 450 ? 300 : 200}
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        // position: "absolute",
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <img src={logo} alt="logo" className="sidebar__heading" />
      <Menu
        theme="dark"
        defaultSelectedKeys={["user"]}
        selectedKeys={[defaultSelected]}
        mode="inline"
        className="sidebar__menu"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={[
          ...(!loading ? nav.filter(menu => checkAccess(menu.roles)).length > 1 ?
            nav.filter(menu => checkAccess(menu.roles)).map(item => {
              return {
                label: item.title,
                key: item.value,

                //icon: (<BarsOutlined className="sidebar__menu-icon" />),
                className: "sidebar__menu-item sidebar__text",
                children: item.submenus.filter(submenu => checkAccess(submenu.roles)).map(submenu => {
                  return {
                    key: submenu.route === "in-developing" ? submenu.title : submenu.route,
                    label: submenu.title,
                    onClick: () => {
                      if (isMobile)
                        setCollapsed(true);
                      //console.log(window.location.hostname, submenu.url, submenu.url.includes(window.location.hostname));
                      if (!submenu.url.includes(window.location.hostname)) {
                        window.open(submenu.url + submenu.route, submenu.blanc ? "_blank" : "_self")

                      } else {
                        history.push("/" + submenu.route)
                      }
                      //window.open("http://localhost:3000/" + submenu.route, "_self")
                    }
                  }
                })
                // onClick: () => {
                //   history.push("/dealers");
                //   if (window.screen.width < 450) {
                //     props.setCollapsed(true);
                //   }
                // }
              }
            })
            :
            nav.filter(menu => checkAccess(menu.roles))[0].submenus.filter(submenu => checkAccess(submenu.roles)).map(item => {

              return {
                label: item.title,
                key: item.route,
                className: "sidebar__menu-item sidebar__text",
                onClick: () => {
                  if (isMobile)
                    setCollapsed(true);
                  //console.log(window.location.hostname, submenu.url, submenu.url.includes(window.location.hostname));
                  if (!item.url.includes(window.location.hostname)) {
                    window.open(item.url + item.route, item.blanc ? "_blank" : "_self")
                    //history.push("/" + item.route)
                  } else {
                    history.push("/" + item.route)
                  }
                }
                // onClick: () => {
                //   history.push("/dealers");
                //   if (window.screen.width < 450) {
                //     props.setCollapsed(true);
                //   }
                // }
              }
            })
            :
            []
          ),
          {
            label: "Выход",
            key: "logout",
            icon: <ArrowLeftOutlined className="sidebar__menu-icon" />,
            className: "sidebar__menu-item sidebar__text",
            onClick: () => {

              cookies.remove("user", { domain: ".vakio.ru" })
              cookies.remove("accessToken", { domain: ".vakio.ru" })
              cookies.remove("refreshToken", { domain: ".vakio.ru" })
              cookies.remove("user")
              cookies.remove("accessToken")
              cookies.remove("refreshToken")
              userStore.logout();
              history.push("/login")
            }
          }
        ]}
      />
    </Layout.Sider >
  );
}
