import React, { useState, useEffect, useContext } from "react";
import { Layout, Button, Typography } from "antd";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import "./Header.scss";
import { Context } from "../..";

import CardPreview from "./CartPreview"

export default function Header(props) {
  const { userStore } = useContext(Context);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showUpdateTaskModal, setShowUpdateTaskModal] = useState(false);
  const location = useLocation()
  const [title, setTitle] = useState("")
  useEffect(() => {
    setTitle("")
    if (location.pathname.includes("factory") || location.pathname.includes("f-editor")) {
      console.log(userStore.user);
      setTitle(userStore.user.name)
    }
    if (location.pathname.includes("f-settings")) {
      setTitle("Операции")
    }

  }, [location.pathname])
  return (
    <Layout.Header className="site-layout-background header">
      <Button
        className="header__menu-button"
        shape="default"
        type="text"
        icon={<MenuOutlined />}
        onClick={() => {
          props.setState(!props.state);
        }}
      />
      <div className="header__title-container">
        <Typography.Title
          level={(window.screen.width) < 450 ? 4 : 3}
          ellipsis={true}
          style={{ maxWidth: "100%" }}
          className="header__title"
        >
          {title}
        </Typography.Title>
      </div>
      <CardPreview />
      <div className="header__actions">
        {/* {((user?.isAdmin && location.pathname.includes("projects")) || ((user?.isAdmin || user?.isSupervisor) && !location.pathname.includes("projects")) && !location.pathname.includes("project_settings")) && <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{
            marginLeft: "auto",
            marginRight: 16,
          }}
          onClick={() => {

            if (location.pathname.includes("projects")) {
              setShowUpdateModal(true);
            } else {
              console.log("THERE");
              setShowUpdateTaskModal(true);
            }
          }}
        >
          {window.screen.width > 450 ? buttonText : ""}
        </Button>} */}


      </div>
    </Layout.Header>
  );
}
